import React, { useEffect, useRef, useState } from 'react'

import { intrestReport, samajReport, searchMatrimonySamajReport } from '../../service/Services';
import { showErrorAlert } from "../../Components/ShowAlertCustome"
import LoadingModal from '../../Forms/LoadingModel';
import { useReactToPrint } from 'react-to-print';
import { Domain } from '../../ApiConfig';
import { downloadPDF } from '../../Helper/ReportsDownloads';
import { gotra, vaishaSamaj, vaishaSamajAndGotra } from "../../constants/staticData.js"
import axios from 'axios';
import { Pagination } from './memberMatrimonyDetailReport.js';
import SearchCriteria from '../../FormsInputes.js/SearchCriteria.jsx';


export default function MatrimonyBySamaj() {
    const [criteria, setCriteria] = useState({ name: "", samal: "", gender: "", userType: "", gotra: "", samaj: "", stustus: "", membershipID: "", mobileNo: "" })
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [byName, setByName] = useState([])
    const [occupation, setOccupation] = useState([])
    const [surename, setSurname] = useState([])
    const [membershipID, setmembershipID] = useState([])
    const [mobileNo, setMobileNo] = useState([])
    const [gotra, setGotra] = useState(vaishaSamajAndGotra[criteria?.samaj])

    const [memberStatus, setMemberStatus] = useState(['Not Activated', 'Activated', 'Block'])
    const [cancelToken, setCancelToken] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [members, setMember] = useState([]);
    const totalItemInPage = 2000;

    const printReport = useRef(null);

    const handlePrint = useReactToPrint({
        documentTitle: "Print This Document",
        onBeforePrint: () => console.log("before printing..."),
        onAfterPrint: () => console.log("after printing..."),
        removeAfterPrint: true,
    });

    useEffect(() => {
        setLoading(true)
        const source = axios.CancelToken.source();
        setCancelToken(source);
        let delayDebounceFn;
        delayDebounceFn = setTimeout(async () => {
            try {
                const headers = {
                    'Content-Type': 'application/json'
                };
                const requestOptions = {
                    headers: headers,
                    withCredentials: true, // Include credentials if necessary
                    params: {
                        page: currentPage,
                        limit: totalItemInPage
                    }
                };
                // console.log('criteria', criteria)
                const memberData = await searchMatrimonySamajReport(criteria, requestOptions)
                // const memberData = await axios.post(`${searchMemberForMemberPage}`, { name, surname, gotra, occupation, city: cityData?.city?.id }, requestOptions);

                if (memberData?.success) {
                    const data = memberData?.data || [];
                    setTotalPages(memberData?.pagination?.totalPages || 1);
                    setMember(data)
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false)
            }
        }, 500);

        return () => {
            clearTimeout(delayDebounceFn);
            source.cancel('Request canceled because a new request was made');
        };
        // fetchData();
    }, [criteria, currentPage])
    // searchMemberForMemberByAdmimForActivation
    // 1 member type, member it, name gontra, registation date, stustus ,
    useEffect(() => {
        const uniqueNames = [...new Set(members.map(member => member.name))];
        setByName(uniqueNames);
        // console.log(uniqueNames)

        const uniqueSurnames = [...new Set(members.map(member => member.surname))];
        setSurname(uniqueSurnames);


    }, [members.length])

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    useEffect(() => {
        setMember(pre => [])
        setTotalPages(1)
        setCurrentPage(pre => 1)
    }, [criteria])

    useEffect(() => {
        setGotra(vaishaSamajAndGotra[criteria?.samaj])
    }, [criteria?.samaj])
    //     onBeforePrint: () => console.log("before printing..."),
    //     onAfterPrint: () => console.log("after printing..."),
    //     removeAfterPrint: true,
    // });
    useEffect(() => {
        setGotra(vaishaSamajAndGotra[criteria?.samaj])
    }, [criteria?.samaj])

    return (
        <div className='mb-20 px-5'>

            <div className="  flex flex-col md:flex-row justify-center items-center flex-wrap  gap-5">

                <div className="   ">
                    {/* samaj */}
                    <SearchCriteria
                        options={vaishaSamaj}
                        search={'samaj'}
                        id={'samaj'}
                        setOptions={setCriteria}
                        label={"समाज"}
                        className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                        style={'  text-black font-bold'}
                    />
                </div>



                {/* gotra */}
                <div className="w-100  ">
                    <SearchCriteria
                        options={gotra}
                        search={'gotra'}
                        id={'gotra'}
                        setOptions={setCriteria}
                        label={"गोत्र"}
                        className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                        style={' text-black font-bold'}
                    />
                </div>


                {/* Gender */}
                <div className="w-100  ">
                    <SearchCriteria
                        options={["Male", "Female"]}
                        search={'gender'}
                        id={'gender'}
                        setOptions={setCriteria}
                        label={"Gender"}
                        className={' w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                        style={' text-black font-bold'}
                    />
                </div>

            </div>


            <LoadingModal show={isLoading} text={'Updating...'} />

            <div ref={printReport}>
                <ReportTable reports={members} />
            </div>
            <div className=' w-full flex justify-center'>
                <button onClick={() => { handlePrint(null, () => printReport.current); }} className=' text-white font-bold px-3 py-1 rounded-xl bg-blue-950'>Print</button>
            </div>

            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
            />
        </div>
    )
}

export const ReportTable = ({ reports }) => {
    return (
        <> <div className='w-full text-center bottom-1 font-bold'>
            <h3>अ.भा.वैश्य परिचय सम्मेलन 2024</h3>
            <h3>मेंबर रजिस्ट्रेशन संख्या - समाज </h3>
        </div>
            <div className="overflow-x-auto">
                <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden border">
                    <thead>
                        <tr className="w-fulL text-sm bg-blue-950 text-white text-left">
                            <th className="py-1   px-2 text-left border-2">क्रमांक</th>
                            <th className="py-1  px-2 text-left border-2">नाम</th>
                            <th className="py-1  px-2 text-left border-2">एप कोड</th>
                            <th className="py-1  px-2 text-left border-2">पुस्तक क्रमांक</th>


                            <th className="py-1 px-2 text-left border-2">Mobile No</th>
                            <th className="py-1 px-2 text-left border-2">Addres</th>
                            <th className="py-1 px-2 text-left border-2">City</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reports.map((intrestReports, index) => {
                            const { personalInformation,
                                photos, matrimonyCode, contactDetails,
                                book_id
                            } = intrestReports;

                            return (
                                <tr key={index} className="odd:bg-white text-sm even:bg-gray-50">
                                    <td className="py-1 px-2 border-2 ">{index + 1}</td>
                                    <td className="py-1 px-2 border-2 flex min-w-56">
                                        <img className=' w-10 h-10 rounded-full me-8' src={Domain + photos?.profilePhoto} />
                                        <div className=' mt-auto'>
                                            <h2 className=' text-black font-bold'>{personalInformation?.name}</h2>
                                            <small>{personalInformation.gotra}</small>
                                        </div>
                                    </td>
                                    <td className="py-1 px-2 border-2">{matrimonyCode}</td>
                                    <td className="py-1 px-2 border-2">{
                                        book_id}</td>
                                    <td className="py-1 px-2 border-2">{contactDetails?.mobileNo}</td>
                                    <td className="py-1 px-2 border-2">{personalInformation?.currentAddress}</td>
                                    <td className="py-1 px-2 border-2">{personalInformation?.currentCity?.cityName}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>


            </div>
        </>
    );
};





export function BySamaj({ selected, setSelected }) {

    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => setIsOpen(!isOpen);
    const selectsamaj = (samaj) => {
        setSelected(samaj);
        setIsOpen(false);
    };

    return (
        <div className="w-full flex  justify-center">
            <div className='w-64'>
                <label className="block text-sm font-medium leading-6 text-gray-900">Samaj Reports</label>
                <div className="relative mt-2">
                    <button
                        onClick={toggleDropdown}
                        className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6"
                    >
                        <span className="flex items-center">
                            {/* <img src={selected.avatar} alt="" className="h-5 w-5 flex-shrink-0 rounded-full" /> */}
                            <span className="ml-3 block truncate">{selected}</span>
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                            <svg
                                className="h-5 w-5 text-gray-400"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M7 7l3-3 3 3"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M7 13l3 3 3-3"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </span>
                    </button>


                    {isOpen && (
                        <ul className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {["All Samaj Report", ...vaishaSamaj].map((samaj, index) => (
                                <li
                                    key={index}
                                    onClick={() => selectsamaj(samaj)}
                                    className={`relative cursor-default select-none py-2 pl-3 pr-9 ${selected === samaj ? 'text-white bg-indigo-600' : 'text-gray-900'
                                        }`}
                                >
                                    <div className="flex items-center">
                                        {/* <img src={samaj.avatar} alt="" className="h-5 w-5 flex-shrink-0 rounded-full" /> */}
                                        <span className={`ml-3 block truncate  `}>
                                            {samaj}
                                        </span>
                                    </div>

                                    {selected === samaj && (
                                        <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600">
                                            <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                <path
                                                    fillRule="evenodd"
                                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm-1.293-9.707a1 1 0 011.414 0L10 9.586l2.293-2.293a1 1 0 011.414 1.414L10 12.414l-2.293-2.293a1 1 0 010-1.414z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        </span>
                                    )}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>
        </div>
    );
}

