import { useEffect, useState } from "react";
import { searchAMtrimonyForAddresPage, updateBookId } from "../../service/Services";
import LoadingModal from "../../Forms/LoadingModel";
import { Pagination } from "./userManagement";
import { Domain } from "../../ApiConfig";
import Swal from "sweetalert2";
import Logo from "../../assets/login card/logo.png"
// import Logo from "../../a"
import { showSuccessAlert } from "../../Components/ShowAlertCustome";

export function AdreesPage() {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const [matrimonyCode, setMatrimonyCode] = useState("");
    const [mobileNo, setMobile] = useState("");
    const [bookIds, setBookIds] = useState({}); // Store book_id for each membershipID
    const [editingId, setEditingId] = useState(null); // Track the editing membershipID

    const limit = 10; // Number of entries per page

    useEffect(() => {
        setCurrentPage(() => 1)
        // Fetch data when the component mounts or when the page/matrimonyCode changes
        fetchData(currentPage, limit, matrimonyCode, mobileNo);
    }, [matrimonyCode, mobileNo]);


    useEffect(() => {
        // Fetch data when the component mounts or when the page/matrimonyCode changes
        fetchData(currentPage, limit, matrimonyCode, mobileNo);
    }, [currentPage]);
    const fetchData = async (page, limit, matrimonyCode, mobileNo) => {
        setLoading(true);
        try {
            // Make the API call with current page, limit, matrimonyCode, and mobileNo
            const response = await searchAMtrimonyForAddresPage(page, limit, matrimonyCode, mobileNo);

            if (response.data) {
                setData(response.data); // Assuming response.data contains the fetched data
                setTotalPages(response.pagination.totalPages); // Assuming response.pagination has totalPages
            } else {
                setData([]);
                setTotalPages(1);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleBookIdChange = (membershipID, newBookId) => {
        setBookIds((prevBookIds) => ({
            ...prevBookIds,
            [membershipID]: newBookId
        }));
    };

    const updatebookId = async (membershipID) => {
        const book_id = bookIds[membershipID] || "";

        if (!book_id) {
            Swal.fire({
                title: "Please enter a Book ID",
                text: "Book ID is required to proceed.",
                icon: "warning",
            });
            return;
        }

        setLoading(true);
        try {
            const isUpdate = await updateBookId({ membershipID, book_id });

            if (isUpdate?.data?.success) {
                showSuccessAlert(isUpdate?.data?.message, Logo);
                // Optionally refresh the data to reflect the changes
                fetchData(currentPage, limit, matrimonyCode, mobileNo);
                setEditingId(null); // Exit edit mode after saving
            } else if (isUpdate?.error) {
                Swal.fire({
                    title: "Error",
                    text: isUpdate.error,
                    icon: "error",
                });
            }
        } catch (error) {
            console.error("Error updating Book ID:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleDoubleClick = (membershipID) => {
        setEditingId(membershipID); // Set the currently edited membership ID
    };

    const handleKeyPress = (e, membershipID) => {
        if (e.key === "Enter") {
            updatebookId(membershipID); // Save changes on Enter
            fetchData(currentPage, limit, matrimonyCode, mobileNo);
        }
    };

    return (
        <>
            {/* Input for Matrimony Code and Mobile Number */}
            <div className="w-full flex justify-center space-x-4">
                <div className="max-w-[350px]">
                    <label htmlFor="MatrimonyAdress" className="capitalize block text-sm font-extrabold text-gray-900">
                        Matrimony Code
                    </label>
                    <input
                        type="text"
                        id="MatrimonyAdress"
                        value={matrimonyCode}
                        onChange={(e) => setMatrimonyCode(e.target.value)}
                        placeholder="Enter Matrimony Code"
                        className="block px-2 w-full rounded-md py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
                    />
                </div>

                <div className="max-w-[350px]">
                    <label htmlFor="mobileNo" className="capitalize block text-sm font-extrabold text-gray-900">
                        Mobile Number
                    </label>
                    <input
                        type="text"
                        id="mobileNo"
                        value={mobileNo}
                        onChange={(e) => setMobile(e.target.value)}
                        placeholder="Enter Mobile Number"
                        className="block px-2 w-full rounded-md py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
                    />
                </div>
            </div>

            <div className="container mx-auto my-5">
                {/* Loading modal */}
                <LoadingModal show={loading} text={'Loading...'} />

                {/* Data Table */}
                <div className="overflow-x-auto">
                    <table className="min-w-full border-collapse">
                        <thead>
                            <tr className="bg-blue-950 text-white text-sm">
                                <th className="py-1 px-2 text-left border-2">क्रमांक</th>
                                <th className="py-1 px-2 text-left border-2">नाम</th>
                                <th className="py-1 px-2 text-left border-2">पता</th>
                                <th className="py-1 px-2 text-left border-2">समाज</th>
                                <th className="py-1 px-2 text-left border-2">एप कोड</th>
                                <th className="py-1 px-2 text-left border-2">पुस्तक क्रमांक</th>
                                <th className="py-1 px-2 text-left border-2">मोबाइल नंबर</th>
                                {/* <th className="py-1 px-2 text-left border-2">पुस्तक में जोड़ें</th> */}
                                <th className="py-1 px-2 text-left border-2">Edit पुस्तक क्रमांक</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.length === 0 ? (
                                <tr>
                                    <td colSpan="8" className="text-center py-4">
                                        No entries found.
                                    </td>
                                </tr>
                            ) : (
                                data.map((entry, index) => {
                                    const {
                                        personalInformation: { name, samaj, gotra, currentAddress },
                                        book_id,
                                        membershipID,
                                        contactDetails: { mobileNo },
                                        photos: { profilePhoto },
                                        matrimonyCode,
                                    } = entry;

                                    return (
                                        <tr key={index} className="odd:bg-white even:bg-gray-50 text-sm">
                                            <td className="py-1 px-2 border-2">{index + 1}</td>
                                            <td className="py-1 px-2 border-2 flex items-center">
                                                <img className="w-10 h-10 rounded-full mr-4" src={Domain + profilePhoto} alt="Profile" />
                                                <div>
                                                    <h2 className="text-black font-bold">{name}</h2>
                                                    <small>{gotra}</small>
                                                </div>
                                            </td>
                                            <td className="py-1 px-2 border-2">{currentAddress}</td>
                                            <td className="py-1 px-2 border-2">{samaj}</td>
                                            <td className="py-1 px-2 border-2">{matrimonyCode}</td>

                                            <td
                                                className="py-1 px-2 border-2"
                                                onDoubleClick={() => handleDoubleClick(membershipID)} // Enable editing on double-click
                                            >
                                                {editingId === membershipID ? (
                                                    <input
                                                        type="text"
                                                        value={bookIds[membershipID] || ""}
                                                        onChange={(e) => handleBookIdChange(membershipID, e.target.value)}
                                                        onKeyPress={(e) => handleKeyPress(e, membershipID)} // Save on Enter key
                                                        className="w-full p-1 border border-gray-300 rounded"
                                                        autoFocus
                                                    />
                                                ) : (
                                                    book_id || "no book id"
                                                )}
                                            </td>

                                            <td className="py-1 px-2 border-2">{mobileNo}</td>
                                            {/* <td className="py-1 px-2 border-2">
                                                <button
                                                    onClick={() => updatebookId(membershipID)}
                                                    className="bg-purple-700 text-white py-1 px-3 rounded"
                                                >
                                                    Add To Book
                                                </button>
                                            </td> */}

                                            <td className="py-1 px-2 border-2">
                                                <button
                                                    onClick={() => handleDoubleClick(membershipID)} // Enable editing
                                                    className="bg-blue-500 text-white py-1 px-3 rounded"
                                                >
                                                    Edit Book ID
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })
                            )}
                        </tbody>
                    </table>

                    {/* Pagination */}
                    <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                </div>
            </div>
        </>
    );
}


