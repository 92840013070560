import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { showErrorAlert, showSuccessAlert } from '../../../Components/ShowAlertCustome.js';
import { SearchCriteria } from '../../../FormsInputes.js';
import { ShowIntrest, searchAMtrimonyForShowInSamelan } from '../../../service/Services';
import useIntersectionObserver from '../../../Helper/useIntersectionObserver.js';
import { setPagination } from '../../../Redux/paginationSlicer.js';
import { vaishaSamajAndGotra } from "../../../constants/staticData.js";
import LoadingModal from '../../../Forms/LoadingModel.js';
import { Domain } from '../../../ApiConfig.js';
import { calculateAge } from '../../../Components/reshtey.js';

export default function VivahPage2() {
    const [currentPage, setCurrentPage] = useState(1);
    const [criteria, setCriteria] = useState({
        name: "", samaj: "", matrimonyCode: "", mobileNo: "", book_id: "",
        gotra: "", cityName: '', cityId: '', occupation: "", gender: "", minAge: "", maxAge: ""
    });
    const [candidates, setCandidates] = useState([]);
    const [gotraOptions, setGotraOptions] = useState(vaishaSamajAndGotra[criteria.samaj]);
    const [cancelToken, setCancelToken] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [intrestLoading, setIntrestLoading] = useState(false);
    const [reciverId, setReciverId] = useState(null);

    const [openProfile, setCloseProfile] = useState(false);

    // const paginationData = useSelector(state => state.pagination.pagination);
    const dispatch = useDispatch();
    // const lastItemRef = useIntersectionObserver({ currentPage, paginationData, setCurrentPage });

    useEffect(() => {
        setLoading(true);
        const source = axios.CancelToken.source();
        setCancelToken(source);

        const fetchData = async () => {
            try {
                const requestOptions = {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true,
                };

                const { name, samaj, gotra, gender, minAge, maxAge, matrimonyCode, mobileNo, book_id, occupation, ...cityData } = criteria;

                const memberData = await searchAMtrimonyForShowInSamelan({
                    name, samaj, book_id, matrimonyCode, gotra, gender, mobileNo, minAge, maxAge, occupation, city: cityData.city?.id
                }, requestOptions);

                if (memberData?.success) {
                    const data = memberData.data || [];
                    setCandidates(data);
                    setCloseProfile(true)
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setCloseProfile(false)
            } finally {
                setLoading(false);
            }
        };

        const delayDebounceFn = setTimeout(fetchData, 500);

        return () => {
            clearTimeout(delayDebounceFn);
            source.cancel('Request canceled');
        };
    }, [currentPage, criteria]);

    useEffect(() => {
        setCandidates([]);
        setCurrentPage(1);
    }, [criteria]);

    // useEffect(() => {
    //     if (reciverId !== null) {
    //         const showInterest = async () => {
    //             try {
    //                 setIntrestLoading(true);
    //                 const isInterestAdded = await ShowIntrest({ revedMembershipID: reciverId });

    //                 if (isInterestAdded.success) {
    //                     showSuccessAlert('Profile added');

    //                 }
    //             } catch (error) {
    //                 showErrorAlert(error);
    //                 setCloseProfile(false)
    //             } finally {
    //                 setIntrestLoading(false);
    //                 setReciverId(null);
    //             }
    //         };
    //         showInterest();
    //     }
    // }, [reciverId]);

    // useEffect(() => {
    //     setGotraOptions(vaishaSamajAndGotra[criteria.samaj]);
    // }, [criteria.samaj]);

    return (
        <>
            <LoadingModal show={intrestLoading} text='Adding' />
            <div className='flex h-full flex-wrap flex-col xl:flex-row gap-2 justify-center'>
                <div className="flex flex-col md:flex-row justify-center items-center flex-wrap gap-5">
                    {/* Matrimony Code */}
                    <div className="w-100">
                        <SearchCriteria
                            search={'matrimonyCode'}
                            id={'matrimonyCode'}
                            setOptions={setCriteria}
                            label={"Matrimony Code"}
                            className='w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'
                        />
                    </div>
                    {/* Mobile No */}
                    <div className="w-100">
                        <SearchCriteria
                            search={'mobileNo'}
                            id={'mobileNo'}
                            setOptions={setCriteria}
                            label={"Mobile Number"}
                            className='w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'
                        />
                    </div>
                    {/* Book ID */}
                    <div className="w-100">
                        <SearchCriteria
                            search={'book_id'}
                            id={'book_id'}
                            setOptions={setCriteria}
                            label={"Book Number"}
                            className='w-[90vw] md:w-[15vw] h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'
                        />
                    </div>
                </div>

                <div className="flex w-full flex-wrap gap-2 justify-center">
                    {(candidates.length !== 0 && openProfile) ? candidates?.map((member, index) => (
                        <div key={index} className={index === candidates.length - 1 ? 'lastItem' : ''} >
                            {/* <h1>Show Profile: {member.name}</h1> */}
                            <ShowProfile profile={member} setCloseProfile={setCloseProfile} />
                        </div>
                    )) : <h1>Profile Not Found</h1>}
                </div>

                {isLoading && (
                    <div className="flex items-center justify-center">
                        <div className="w-16 h-16 border-t-4 border-b-4 border-blue-500 rounded-full animate-spin"></div>
                    </div>
                )}
            </div>
        </>
    );
}
const ShowProfile = ({ profile, setCloseProfile }) => {


    const {
        _id,
        userType,
        showMobileNo,
        membershipID,
        matrimonyCode,
        matrimonyActivationStatus,
        marriedStatus,
        personalInformation: {
            name,
            surname,
            samaj,
            gotra,
            skinColor,
            handicapped,
            weightInKG,
            heightFeet,
            heightInch,
            gender,
            currentMaritalStatus,
            currentAddress,
            currentPincode,
            currentCity: { cityID, cityName, stateID: cityStateID, countryID: cityCountryID },
            currentState: { stateID: stateStateID, stateName, stateShortName, countryID: stateCountryID },
            currentCountry: { countryName, countryID: countryCountryID },
            motherTongue,
            languagesKnown,
            bloodGroup
        },
        // divorceDetails: { divorceDate, childrenDetails },
        kundliDetails: { birthDateTime, birthPlace, mangalik },
        educationQualification,
        occupationDetails: { occupation, companyName, designation, occupationCityText, averageSalaryPerMonth },
        contactDetails: { mobileNo, whatsappNo, emailId },
        familyDetail: {
            fatherName,
            fatherOccupation,
            fatherMobileNo,
            fatherMamaGotra,
            motherName,
            motherMamaGotra,
            brother,
            marriedBrother,
            unmarriedBrother,
            sisters,
            marriedSister,
            unmarriedSister
        },
        mamaDetails: { mamaName, mamaGotra },
        // samajReferenceDetails: {
        //     reference1Name,
        //     reference1Occupation,
        //     reference1MobileNo,
        //     reference1Relation
        // },
        othersDetails: { home, wheeler4, wheeler2 },
        // extraInformation: { anyHealthIssues, specialQualities },
        photos: { profilePhoto, personalPhoto1, personalPhoto2 },
        // remarksNotes,
        // Qr,
        // interstRecived,
        // intrestedIn,
        // entryDatetime,
        // updateDate,
        // samajToShow,
        // createdAt,
        // updatedAt,
        // matrimonyEntryId,
        // __v,
        book_id,
        activatedDateTime
    } = profile;

    return (
        <div className=' absolute top-0 z-[999999999] left-0 w-screen h-screen shadow-2xl rounded-3xl p-3 bg-white flex flex-col justify-start'>
            <div className='flex justify-center text-center gap-5'>
                <p className='bg-blue-900 w-full gap-5 max-w-[300px] px-3 sm:px-4 lg:px-5 rounded-xl py-1 text-sm sm:text-base lg:text-lg xl:text-xl text-[#FFF411] font-semibold'>
                    App ID : {matrimonyCode}
                </p>

                <p className='bg-blue-900 w-full max-w-[300px] px-3 sm:px-4 lg:px-5 rounded-xl py-1 text-sm sm:text-base lg:text-lg xl:text-xl text-[#FFF411] font-semibold'>
                    Book ID  :{book_id}
                </p>
            </div>
            <div className={`flex mt-2 rounded-t-xl justify-center font-bold items-center text-white h-100  w-full ${gender === 'Male' ? 'metrimony-card-bg-male-head' : 'bg-[#EC268F]'}`}>
                <p className='text-sm sm:text-base py-3 lg:text-lg xl:text-[2.3rem]'>{name}</p>
            </div>
            <div className={`rounded-b-2xl relative w-full ${gender === 'Male' ? 'metrimony-card-bg-male-body' : 'metrimony-card-bg'} flex flex-col border-dashed border-gray-500 border-b-2 border-e-2 border-s-2`}>
                <div className='w-full flex justify-center mt-[-1rem] xl:mt-[0rem] xl:pb-3'>

                    <div className='w-[30%] text-center font-bold flex flex-col justify-center ms-4'>
                        <figure className=' text-2xl  text-[#FFF411]  mb-3' > <p>मोबाइल नंबर:</p>  {mobileNo}</figure>
                        <img className='object-cover' src={`${Domain}${profilePhoto}`} loading='lazy' />
                    </div>
                    <div className="text-[#FFF411] ms-10 flex-col leading-[1rem] text-xs sm:text-sm md:text-base lg:text-lg font-bold justify-center w-full py-4 md:py-6 xl:py-0">
                        <div className='h-full ms-3 sm:ms-5 xl:ms-7 pt-2 sm:pt-4'>
                            {/* Personal Info Section */}

<div className="flex justify-between mt-0">
                                <p className="w-[30%] sm:w-[20%] xl:w-[15%]">जन्म तिथि</p>
                                <p className="w-[70%] metrimony-card-colone">{new Date(birthDateTime).toLocaleDateString()}</p>
                            </div>


                            <div className="flex justify-between mt-0">
                                <p className="w-[30%] sm:w-[20%] xl:w-[15%]">जन्म स्थान</p>
                                <p className="w-[70%] metrimony-card-colone">{birthPlace}</p>
                            </div>

<div className="flex justify-between mt-0">
                                <p className="w-[30%] sm:w-[20%] xl:w-[15%]">शिक्षा योग्यता</p>
                                <p className="w-[70%] metrimony-card-colone">{educationQualification}</p>
                            </div>

<div className="flex justify-between mt-0">
                                <p className="w-[30%] sm:w-[20%] xl:w-[15%]">लंबाई</p>
                                <p className="w-[70%] metrimony-card-colone">{heightFeet}.{heightInch} feet</p>
                            </div>



                            <div className="flex justify-between">
                                <p className="w-[30%] sm:w-[20%] xl:w-[15%]">गोत्र</p>
                                <p className="w-[70%] metrimony-card-colone">{gotra}</p>
                            </div>

<div className="flex justify-between mt-0">
                                <p className="w-[30%] sm:w-[20%] xl:w-[15%]">व्यवसाय</p>
                                <p className="w-[70%] metrimony-card-colone">{occupation}</p>
                            </div>

<div className="flex justify-between mt-0">
                                <p className="w-[30%] sm:w-[20%] xl:w-[15%]">मा.आया</p>
                                <p className="w-[70%] metrimony-card-colone">{averageSalaryPerMonth || "not provided"} </p>
                            </div>

<div className="flex justify-between mt-0">
                                <p className="w-[30%] sm:w-[20%] xl:w-[15%]">कार्यो स्थल</p>
                                <p className="w-[70%] metrimony-card-colone">{occupationCityText}</p>
                            </div>

                            {/* Add more details below */}
<div className="flex justify-between mt-0">
                                <p className="w-[30%] sm:w-[20%] xl:w-[15%]">पिता का नाम</p>
                                <p className="w-[70%] metrimony-card-colone">{fatherName}</p>
                            </div>

<div className="flex justify-between mt-0">
                                <p className="w-[30%] sm:w-[20%] xl:w-[15%]">पिता का व्यवसाय</p>
                                <p className="w-[70%] metrimony-card-colone">{fatherOccupation}</p>
                            </div>

<div className="flex justify-between mt-0">
                                <p className="w-[30%] sm:w-[20%] xl:w-[15%]">पता</p>
                                <p className="w-[70%] metrimony-card-colone">{currentAddress} {stateShortName}</p>
                            </div>


<div className="flex justify-between mt-0">
                                <p className="w-[30%] sm:w-[20%] xl:w-[15%]">रंग / वज़न</p>
                                <p className="w-[70%] metrimony-card-colone">{skinColor}-{weightInKG}किलो </p>
                            </div>

<div className="flex justify-between mt-0">
                                <p className="w-[30%] sm:w-[20%] xl:w-[15%]">मामा गोत्र</p>
                                <p className="w-[70%] metrimony-card-colone">{mamaGotra} </p>
                            </div>


<div className="flex justify-between mt-0">
                                <p className="w-[30%] sm:w-[20%] xl:w-[15%]">मांगलिक</p>
                                <p className="w-[70%] metrimony-card-colone">{mangalik} </p>
                            </div>


                            {/* <div className="flex justify-between mt-2">
                                <p className="w-[30%] sm:w-[20%] xl:w-[15%]">वर्त्तमान शहर</p>
                                <p className="w-[70%] metrimony-card-colone">{cityName}</p>
                            </div>

<div className="flex justify-between mt-0">
                                <p className="w-[30%] sm:w-[20%] xl:w-[15%]">वैवाहिक स्थिति</p>
                                <p className="w-[70%] metrimony-card-colone">{currentMaritalStatus}</p>
                            </div> */}


                            {/* <div className="flex justify-between mt-2">
                                <p className="w-[30%] sm:w-[20%] xl:w-[15%]">Mother Name</p>
                                <p className="w-[70%] metrimony-card-colone">{motherName}</p>
                            </div>
<div className="flex justify-between mt-0">
                                <p className="w-[30%] sm:w-[20%] xl:w-[15%]">Mobile No.</p>
                                <p className="w-[70%] metrimony-card-colone">{mobileNo}</p>
                            </div>
<div className="flex justify-between mt-0">
                                <p className="w-[30%] sm:w-[20%] xl:w-[15%]">Email ID</p>
                                <p className="w-[70%] metrimony-card-colone">{emailId}</p>
                            </div>
<div className="flex justify-between mt-0">
                                <p className="w-[30%] sm:w-[20%] xl:w-[15%]">Blood Group</p>
                                <p className="w-[70%] metrimony-card-colone">{bloodGroup}</p>
                            </div> */}
                            {/* Continue adding other fields in the same way */}
                        </div>
                    </div>
                </div>
            </div>
            <div className=' flex justify-evenly font-bold mt-2'>
                {/* <button onClick={openModal} className=' bg-blue-900 text-sm cursor-pointer text-white py-1 px-5 rounded-lg'>View Profile </button> */}
                <button onClick={() => setCloseProfile(false)} className=' bg-blue-900 text-sm cursor-pointer text-white py-1 px-5 rounded-lg'>Close</button>
            </div>
        </div>
    );
};
