import React, { memo, useEffect, useMemo, useState } from 'react';
import { Link } from "react-router-dom"

import committee from "../../../assets/dashbordBtn/committee.png";
import samelanPass from "../../../assets/dashbordBtn/samelanPass.png";
import mandirdarshan from "../../../assets/dashbordBtn/mandirdarshan.png";
import rishtey from "../../../assets/dashbordBtn/vaishyaRistrey.png";

import bookdharmashala from "../../../assets/dashbordBtn/bookdharmashala.png";
import programs from "../../../assets/dashbordBtn/programs.png";
import bloodbank from "../../../assets/dashbordBtn/bloodbank.png";
import donations from "../../../assets/dashbordBtn/donations.png";

import achievements from "../../../assets/dashbordBtn/achievements.png";
import samajcalender from "../../../assets/dashbordBtn/samajcalender.png";
import digitalcard from "../../../assets/dashbordBtn/digitalcard.png";
import promotion from "../../../assets/dashbordBtn/promotion.png";

import complaints from "../../../assets/dashbordBtn/complaints.png";
import invitations from "../../../assets/dashbordBtn/invitations.png";
import vaishyasamachar from "../../../assets/dashbordBtn/vaishyasamachar.png";
import jobs from "../../../assets/dashbordBtn/jobs.png";
import careerGuidance from "../../../assets/dashbordBtn/careerGuidance.png"
import artical from "../../../assets/dashbordBtn/artical.png"
import gatividhiya from "../../../assets/dashbordBtn/vaishyaGatividhiya.png"
import samajSewa from "../../../assets/dashbordBtn/samajsewa.png"
import profile from "../../../assets/dashbordBtn/vaishyaProfile.png"
import ruls from "../../../assets/dashbordBtn/rules.png"
import gallery from "../../../assets/dashbordBtn/gallery.png"
import vivahGallery from "../../../assets/dashbordBtn/vivahgallery.png"
import Interest from "../../../assets/dashbordBtn/interest.png"

import businessprofile from "../../../assets/dashbordBtn/businessprofile.png";
import registration from "../../../assets/dashbordBtn/registration.png";
import { HeaderBoarder } from '../../../Components';


import Ads from '../../../assets/MandirImg/chikitsa shivir.jpg';
import { useSelector } from 'react-redux';

const menuButtons = [
    // { filename: "mandirdarshan", file: mandirdarshan, url: "/mandirdarshan" },
    { filename: "committee", file: committee, url: "/committee" },
    { filename: "samelanentry", file: samelanPass, url: "/samelanentry" },
    { filename: "profile", file: profile, url: "/profileEdit" },
    { filename: "vivahGallery", file: vivahGallery, url: "/vivahGallery" },
    { filename: "rishtey", file: rishtey, url: "/rishtey" },
    { filename: "Interest", file: Interest, url: "/intrest" },
    { filename: "vaishyasamachar", file: vaishyasamachar, url: "/news" },
    { filename: "gatividhiya", file: gatividhiya, url: "/gatividhiya" },
    { filename: "ruls", file: ruls, url: "/rules" },
    { filename: "gallery", file: gallery, url: "/gallery" },
    // { filename: "gallery", file: gallery, url: "/gallery" },

    // { filename: "bookdharmashala", file: bookdharmashala, url: "/bookdharmashala" },
    // { filename: "programs", file: programs, url: "/programs" },
    // { filename: "bloodbank", file: bloodbank, url: "/bloodbank" },
    // { filename: "donations", file: donations, url: "/donations" },
    // { filename: "achievements", file: achievements, url: "/achievements" },
    // { filename: "samajcalender", file: samajcalender, url: "/samajcalender" },
    // { filename: "digitalcard", file: digitalcard, url: "/digitalcard" },
    // { filename: "promotion", file: promotion, url: "/promotion" },
    // { filename: "complaints", file: complaints, url: "/suzhav" },
    // { filename: "invitations", file: invitations, url: "/invitations" },

    // { filename: "jobs", file: jobs, url: "/jobs" },
    // { filename: "careerGuidance", file: careerGuidance, url: "/careerGuidance" },
    // { filename: "businessprofile", file: businessprofile, url: "/businessprofile" },
    // { filename: "registration", file: registration, url: "/registration" },
    // { filename: "artical", file: artical, url: "/artical" },
    // { filename: "samajSewa", file: samajSewa, url: "/samajSewa" },





];

function HomeDashboard() {
    const isAuthenticated = useSelector(state => state?.auth);
    const [isOpen, setOpen] = useState(true)
    useEffect(() => {
        const hasModalBeenShown = sessionStorage.getItem('hasModalBeenShown');
        if (hasModalBeenShown) {
            setOpen(false); // Set isOpen to false if the modal has been shown before
        }
        //  else {
        // }
    }, []);
    const images = useMemo(() => (menuButtons), [])



    return (
        <>
            {/* <Header /> */}
            <HeaderBoarder heading={'उम्मीदवार डैशबोर्ड'} />
            {/* <ShowAds isOpen={isOpen} toggleModal={setOpen} /> */}
            <div className='flex flex-wrap  items-start  px-5  '>
                <div className='flex flex-wrap  items-start sm:px-5 mb-5  '>
                    {images.map((image, index) => (
                        <Link to={'/dashboard' + image.url} key={index}>
                            <img className='h-[6rem] md:h-[9rem]' src={image.file} alt={`${image.filename} ${index + 1}`} loading="lazy" />
                        </Link>
                    ))}

                    {/* <Link className=' text-bold px-5 py-3 text-center ' to='/dashboard/vivahGallery2' >Show In Samelan</Link> */}

                    {(isAuthenticated?.isLogin && isAuthenticated?.user?.userType === 1) && <div className='h-[5rem] md:h-[8rem] mt-2 mx-2 rounded-md bg-blue-700 text-white flex justify-center items-center'>
                        <Link className=' text-bold px-5 py-3 text-center ' to='/admin' >AdminDetails</Link>
                    </div>}
                </div>
            </div>
            {/* <Footer /> */}
        </>
    );
}

export default memo(HomeDashboard)


const ShowAds = ({ isOpen, toggleModal }) => {
    const seen = () => {
        sessionStorage.setItem('hasModalBeenShown', 'true'); // Store in local storage that the modal has been shown
        toggleModal(false)
    }
    return (
        <>
            {isOpen && (
                <div
                    id="static-modal"
                    data-modal-backdrop="static"
                    tabIndex="-1"
                    aria-hidden="true"
                    className="fixed inset-0 flex justify-center items-center z-50 bg-gray-900 bg-opacity-50"
                >
                    <div className="relative flex flex-col items-center bg-white  max-w-sm  rounded-lg">
                        {/* Modal content */}
                        <button
                            onClick={seen}
                            type="button"
                            className="absolute top-0 right-0 text-3xl font-bold   bg-transparent   rounded-lg  inline-flex justify-center items-center"
                        >
                            <svg
                                className="w-6 h-6"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                        {/* Add your ad content here */}
                        {/* <div className='font-bold text-xl text-center my-2'>
                            <p className=' '> भारत के पहले पूर्ण डिजिटल समाज मैं  </p>
                            <p className=' '> आपका स्वागत है </p>
                        </div> */}
                        <img className=" w-64 h-[100%]" src={Ads} />
                    </div>
                </div>
            )}
        </>
    );
};

