import React, { Fragment, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { HeaderBoarder } from '../Components'
import { CheackBox, CityDropDown, Input, PhotoUpload, SearchDropDownCriteria, SelectInput, Textarea } from '../FormsInputes.js';
import { useDispatch, useSelector } from 'react-redux';
import FormHeading from './FormHeading.js';
import { addMatrimonyProfile, genarateMatrimonySharebleQr, isMobileNumberRegisterd, upDateMember } from '../service/Services.js';
import { Link, useNavigate } from 'react-router-dom';
import Logo from "../assets/login card/logo.png"
import Swal from 'sweetalert2';
import { showErrorAlert, showSuccessAlert } from '../Components/ShowAlertCustome.js';
import { gotra, vaishaSamaj, vaishaSamajAndGotra } from "../constants/staticData.js"
import { Domain, clientDomain } from '../ApiConfig.js';
import { faLinesLeaning } from '@fortawesome/free-solid-svg-icons';
import LoadingModal from './LoadingModel.js';
import { ShowQrCode } from '../Components/PopupModel.js';
import { updateMember } from '../Redux/authSlicer.js';
import { occupations } from "../constants/staticData.js"
import { validateFormData } from "../Helper/validation.js"
import { TermsAndConditions } from './registrationForm.js';
import AvatarEditorCom from './AvatarEditor.js';
// import Calendar from 'react-calendar';


const bloodGroups = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];
const skinColor = ['fair', 'dark', 'medium']
const motherTongue = ['Hindi', 'English', 'Marathi']

const rashis = [
    "मेष",   // Aries (मेष)
    "वृष",   // Taurus (वृषभ)
    "मिथुन", // Gemini (मिथुन)
    "कर्क",   // Cancer (कर्कट)
    "सिंह",   // Leo (सिंह)
    "कन्या",  // Virgo (कन्या)
    "तुला",   // Libra (तुला)
    "वृश्चिक", // Scorpio (वृश्चिक)
    "धनु",    // Sagittarius (धनु)
    "मकर",    // Capricorn (मकर)
    "कुंभ",   // Aquarius (कुंभ)
    "मीन"     // Pisces (मीन)
]

const relations = ["Wife", "Mother", "Father", "Son",
    "Daughter",
    "Brother", "Sister",
    "Brother's Wife",
    "Grandmother",
    "Grandfather", "Other"]
const currentMarritalStatus = [
    'Unmarried', 'Remarriage', 'Widow', 'Single', 'Divorcee'
]

const initialFormData = {
    personalInformation: {
        name: "",
        surname: "",
        gotra: "अन्य गोत्र",
        skinColor: "",
        motherTongue: "",
        languagesKnown: "",
        weightInKG: "",
        heightFeet: "",
        heightInch: "",
        bloodGroup: "",
        gender: "",
        currentMaritalStatus: "",
        permanentAddress: "",
        permanentCity: "",
        permanentPincode: "",
        currentAddress: "",
        currentCity: "",

        currentPincode: "",
    },
    divorceDetails: {
        divorceDate: "",
        divorceReason: "",
        childrenDetails: [{ Children: "", ChildrenAge: 0 },
        { Children: "", ChildrenAge: 0 },
        { Children: "", ChildrenAge: 0 }]
    },

    kundliDetails: {
        birthDateTime: "",
        birthPlace: "",
        rashiByDateOfBirth: "",
        rashi: "",
        mangalik: "",
    },

    occupationDetails: {
        occupation: "",
        companyName: "",
        designation: "",
        occupationCity: "",
        occupationCityText: "",
        occupationState: "",
        occupationCountry: "",
        averageSalaryPerMonth: "",
    },

    contactDetails: {
        mobileNo: "",
        whatsappNo: "",
        facebookId: "",
        linkedInId: "",
        instagramId: "",
        emailId: "",
    },

    familyDetail: {
        fatherName: "",
        fatherOccupation: "",
        fatherMobileNo: "",
        fatherMamaGotra: "",
        motherName: "",
        motherOccupation: "",
        motherMobileNo: "",
        motherMamaGotra: "",
        brother: "",
        marriedBrother: "",
        unmarriedBrother: "",
        sisters: "",
        marriedSister: "",
        unmarriedSister: "",
        residingWithFamily: "",
        currentFamilyType: "",
        interestedInFamilyType: "",
    },
    mamaDetails: {
        mamaName: "",
        mamaOccupation: "",
        mamaMobileNo: "",
        mamaCity: "",
        mamaState: "",
        mamaCountry: "",
        mamaGotra: "",

    },
    othersDetails: {
        achievementsAwards: "",
        expectationFromRelation: "",
        hobbies: "",
        home: "",
        wheeler4: false,
        wheeler2: false
    },
    samajReferenceDetails: {
        reference1Name: "",
        reference1City: "",
        reference1State: "",
        reference1Country: "",
        reference1Occupation: "",
        reference1MobileNo: "",
        reference1Relation: "",
        reference2Name: "",
        reference2City: "",
        reference2State: "",
        reference2Country: "",
        reference2Occupation: "",
        reference2MobileNo: "",
        reference2Relation: "",
    },

    extraInformation: {
        anyHealthIssues: "",
        specialQualities: "",
    },
    remarksNotes: "",
    educationQualification: "",
    wheeler4: false,
    wheeler2: false
}

export default function MatrimonyRegistration({ adminEditData, togglePopup }) {
    const useData = useSelector(state => state?.auth);
    let authData
    // if (adminEditData) {
    //     authData = {};
    // } else {

    // }

    authData = useSelector(state => state.auth);

    const formData = adminEditData ?? authData?.user ?? initialFormData;

    // const formData = initialFormData;
    // const useformHookdata = adminEditData ? adminEditData : initialFormData;

    const { register, handleSubmit, setError, watch, formState: { errors }, setValue, getValues, reset } = useForm({ ...formData });
    const [state, setState] = useState([])
    const [country, setCountry] = useState([])
    const [city, setCity] = useState([])
    const [age, setAge] = useState("")
    const [maritalStatus, setSaritalStatus] = useState(formData?.personalInformation?.currentMaritalStatus || "")
    const [qr, setQr] = useState("")
    const [value, setValueLocation] = useState([]);
    const [reCapcha, setreCapchToken] = useState("");
    const [citis, setCitis] = useState({});
    const [cityOptions, setCityOptions] = useState([]);
    const [isLoading, setIsloading] = useState(false)
    const [isApiCall, setIsApiCall] = useState(false)
    const [termAndConditions, setTermAndConditions] = useState(authData.isLogin)
    const [isOpen, setIsOpen] = useState(false);
    const [permanentCity, setPermanentCity] = useState(formData?.personalInformation?.permanentCity);
    const [currentCity, setCurrentCity] = useState(formData?.personalInformation?.currentCity);
    const [occupationCity, setOccupationCity] = useState(formData?.occupationDetails?.occupationCity);
    const [mamaCity, setMamaCity] = useState(formData?.mamaDetails?.mamaCity);
    const [reference1City, setReference1City] = useState(formData?.samajReferenceDetails?.reference1City);
    const [reference2City, setReference2City] = useState(formData?.samajReferenceDetails?.reference2City);
    const [gotra, setGotra] = useState(vaishaSamajAndGotra[formData?.personalInformation?.samaj] || [])
    const [avatar, setEditedAvatar] = useState("")
    const [home, setHome] = useState(formData?.othersDetails?.home)
    const [handicapped, setHandicapped] = useState(formData?.personalInformation?.handicapped || "NO")
    const [isMoblieNumberAlreadyExist, setsIMoblieNumberAlreadyExist] = useState(false)
    const [mobileNumberCheckLoading, setMobileNumberCheckLoading] = useState(false)

    const [vehical, setVehicale] = useState({
        wheeler2: formData?.othersDetails?.wheeler2,
        wheeler4: formData?.othersDetails?.wheeler4
    })


    const dispatch = useDispatch()
    const navigate = useNavigate()


    function calculateAgeFromDateTime(e, preSelectedDate) {
        const dateTimeString = e.target.value;


        const today = new Date();


        // Parse the input datetime string
        const dob = new Date(dateTimeString);


        let age = today.getFullYear() - dob.getFullYear();
        const monthDiff = today.getMonth() - dob.getMonth();
        const dayDiff = today.getDate() - dob.getDate();

        if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
            age--;
        }

        // Calculate the additional fraction of the year
        let additionalMonths = monthDiff < 0 ? 12 + monthDiff : monthDiff;
        let additionalDays = dayDiff < 0 ? new Date(today.getFullYear(), today.getMonth(), 0).getDate() + dayDiff : dayDiff;

        let fractionalYear = additionalMonths / 12;
        fractionalYear += additionalDays / 365; // Approximate by assuming 365 days in a year

        age += fractionalYear;

        setValue('birthDateTime', dateTimeString);
        // setValue('age', age.toFixed(1)); // Keep two decimal places for clarity
        setAge(age.toFixed(1) + " Years");
    }

    useEffect(() => {
        setValue('wheeler4', formData?.othersDetails?.wheeler4)
        setValue('wheeler2', formData?.othersDetails?.wheeler2)
        setValue('surname', 'currentry not required')

    }, [])

    const handelTermsAndConditions = (e) => {
        if (e.target.checked) {
            setIsOpen(true)
            setTermAndConditions(true)
        } else {
            setIsOpen(false)
            setTermAndConditions(false)
        }

        // Toggle the checked state
        // setIsChecked(!termAndConditions);
        // Call the togglePopup function
    };

    useEffect(() => {

        function calculateAgeFromDateTime(preSelectedDate) {
            const dateTimeString = preSelectedDate;
            const today = new Date();
            // Parse the input datetime string
            const dob = new Date(dateTimeString);
            let age = today.getFullYear() - dob.getFullYear();
            const monthDiff = today.getMonth() - dob.getMonth();
            const dayDiff = today.getDate() - dob.getDate();

            if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
                age--;
            }

            // Calculate the additional fraction of the year
            let additionalMonths = monthDiff < 0 ? 12 + monthDiff : monthDiff;
            let additionalDays = dayDiff < 0 ? new Date(today.getFullYear(), today.getMonth(), 0).getDate() + dayDiff : dayDiff;

            let fractionalYear = additionalMonths / 12;
            fractionalYear += additionalDays / 365; // Approximate by assuming 365 days in a year

            age += fractionalYear;

            // setValue('birthDateTime', dateTimeString);
            // setValue('age', age.toFixed(1)); // Keep two decimal places for clarity
            setAge(age.toFixed(1) + " Years");
        }
        if (formData?.kundliDetails?.birthDateTime) {
            calculateAgeFromDateTime(formData?.kundliDetails?.birthDateTime)
        }
    }, [])

    const handleChange = (fieldName, e) => {
        let value = e.target.value
        const mobileMuners = ['whatsappNo', 'mobileNo', 'fatherMobileNo', 'motherMobileNo', 'mamaMobileNo', 'reference1MobileNo', 'reference2MobileNo']
        if (mobileMuners.includes(fieldName)) {
            // Remove non-numeric characters
            value = value.replace(/\D/g, '');
            if (value.length > 10) {
                value = value.slice(0, 10); // Limit the value to 10 digits
            }
            setValue(fieldName, value, { shouldValidate: true });
        } else {
            if (fieldName == "samaj") {
                setGotra(() => vaishaSamajAndGotra[value])
            }

            console.log(fieldName, value)
            setValue(fieldName, value, { shouldValidate: true });
        }
    };

    const handleRegisterMobileNoChange = async (e) => {
        let value = e.target.value;
        setMobileNumberCheckLoading(false);
        setsIMoblieNumberAlreadyExist(false);

        // Remove non-numeric characters
        value = value.replace(/\D/g, '');

        if (value.length > 10) {
            value = value.slice(0, 10); // Limit the value to 10 digits
        }

        setValue("mobileNo", value, { shouldValidate: true });

        if (value.length === 10) {
            setsIMoblieNumberAlreadyExist(true);
            await mobileNumberCheckHandler(value);
        }
    };

    const mobileNumberCheckHandler = async (number) => {
        try {
            const responce = await isMobileNumberRegisterd({ register_MobileNumber: number })
            console.log("responce", responce);

            if (responce?.success) {
                setMobileNumberCheckLoading(true)
            }
        } catch (error) {
            console.log(error)
            setError('mobileNo', {
                type: 'manual',
                message: 'Mobile Number Already Exists',
            });
            // setMobileNumberCheckLoading(true)
        } finally {
            setsIMoblieNumberAlreadyExist(false)
        }

    }

    const handleFileChange = (fileName, e) => {
        const file = e.target.files[0]; // Get the first file from the input
        setValue(fileName, file);
    };
    const handleMarritalStatus = (fieldName, e) => {
        const value = e.target.value;
        setValue(fieldName, value, { shouldValidate: true });
        setSaritalStatus(value)
    };

    // useEffect(() => {
    //     if (!(authData?.isLogin) && watch('gender') === "Male") {
    //         Swal.fire({
    //             title: 'पंजीकरण केवल महिलाओं के लिए स्वीकार्य है',
    //             text: 'पंजीकरण केवल महिलाओं के लिए स्वीकार्य है',
    //             icon: 'warning',
    //         });
    //         return;
    //     }
    //     // console.log("watch('gender')", watch('gender'))
    // }, [watch('gender')])



    // addMatrimonyProfile
    const handleFromSubmit = async (data) => {

        // if (data?.gender === "Male") {
        //     Swal.fire({
        //         title: 'पंजीकरण केवल महिलाओं के लिए स्वीकार्य है',
        //         text: 'पंजीकरण केवल महिलाओं के लिए स्वीकार्य है',
        //         icon: 'warning',
        //     });
        //     return;
        // }

        if (!currentCity?.city?.cityID && !currentCity?.city?.cityName && !currentCity?.city?.stateID) {
            Swal.fire({
                title: 'Please select city',
                text: 'Please select city',
                icon: 'warning',
            });
            return;
        }



        if (!termAndConditions) {
            Swal.fire({
                title: 'Please read terms and conditions',
                text: 'Please read terms and conditions',
                icon: 'warning',
            });
            return;
        }
        if (!avatar) {
            Swal.fire({
                title: 'Please add Photo',
                text: 'Please add Photo',
                icon: 'warning',
            });
            return;
        }

        const bodyFields = [
            'name', 'surname', 'gotra', 'skinColor', 'weightInKG',
            'heightFeet', 'heightInch', 'gender', 'currentMaritalStatus',
            'currentAddress', 'currentCity', 'currentPincode', 'birthDateTime', 'birthPlace',
            'mangalik', 'occupation',
            'mobileNo', 'whatsappNo', 'fatherName',
            'motherName', 'brother', 'marriedBrother', 'unmarriedBrother',
            'sisters', 'marriedSister', 'unmarriedSister',
            'mamaName', 'mamaGotra',
            'entrySamajId', 'samaj', 'wheeler4', 'wheeler2', 'home'
        ];

        if (data?.home == null || data?.home == "null" || !data?.home) {
            Swal.fire({
                title: 'warning',
                text: "Home Details required",
                icon: 'warning',
            });
            return;
        }

        if (currentCity?.name == "" || !currentCity) {
            showErrorAlert("please Select City")
            return
        }
        try {
            setIsApiCall(true)
            const formData = new FormData()
            for (let key in data) {
                formData.append(key, data[key])
            }
            const entryInSamaj = {
                curentSamaj: "Indor porwal samaj",
                id: 1
            }


            formData.append('entrySamajId', JSON.stringify(entryInSamaj));
            formData.append('currentCity', JSON.stringify(currentCity))
            // formData.append('occupationCity', JSON.stringify(occupationCity))
            formData.append('reference1City', JSON.stringify(reference1City))
            formData.append('profilePhoto', avatar)

            validateFormData(formData, bodyFields)

            const response = await addMatrimonyProfile(formData)

            if (response?.success) {
                navigate('/')
                reset();
                showSuccessAlert(response.message, Logo)
            } else
                if (response.error) {
                    Swal.fire({
                        title: response.error,
                        text: response.error,
                        icon: 'error',
                    });
                }

        } catch (error) {

            Swal.fire({
                title: error,
                text: error,
                icon: 'error',
            });
            setIsApiCall(false)
        } finally {
            setIsApiCall(false)
        }
    };
    const handleFromEditSubmit = async (data) => {

        const bodyFields = [
            'name', 'surname', 'gotra', 'skinColor', 'weightInKG',
            'heightFeet', 'heightInch', 'gender', 'currentMaritalStatus',
            'currentAddress', 'currentCity', 'currentPincode', 'birthDateTime', 'birthPlace',
            'mangalik', 'occupation',
            'mobileNo', 'whatsappNo', 'fatherName',
            'motherName', 'brother', 'marriedBrother', 'unmarriedBrother',
            'sisters', 'marriedSister', 'unmarriedSister',
            'mamaName', 'mamaGotra',
            'entrySamajId', 'samaj', 'wheeler4', 'wheeler2', 'home'
        ];
        if (data?.home == null || data?.home == "null" || !data?.home) {
            Swal.fire({
                title: 'warning',
                text: "Home Details required",
                icon: 'warning',
            });
            return;
        }

        setIsApiCall(true)
        try {
            const formData = new FormData()
            for (let key in data) {
                formData.append(key, data[key])
            }
            const entryInSamaj = {
                curentSamaj: "Indor porwal samaj",
                id: 1
            }
            formData.append('entrySamajId', JSON.stringify(entryInSamaj));
            // formData.append('permanentCity', JSON.stringify(permanentCity))
            formData.append('currentCity', JSON.stringify(currentCity))
            // formData.append('occupationCity', JSON.stringify(occupationCity))
            // formData.append('mamaCity', JSON.stringify(mamaCity))
            formData.append('reference1City', JSON.stringify(reference1City))
            formData.append('profilePhoto', avatar);
            // formData.append('reference2City', JSON.stringify(reference2City))
            validateFormData(formData, bodyFields)
            let response
            if (adminEditData) {
                const headers = {
                    'Content-Type': 'multipart/form-data',
                };
                const requestOptions = {
                    headers: headers,
                    withCredentials: true, // Include credentials if necessary
                };
                formData.append('membershipID', adminEditData?.membershipID);
                // console.log('adminEditData', adminEditData)
                response = await upDateMember(formData, 4, requestOptions)
                console.log("response", response)
            } else {
                response = await dispatch(updateMember({ uploadForm: formData, userType: 4 }))
            }
            if (response?.payload?.success) {
                showSuccessAlert(response?.payload?.message, Logo)
            } else if (response?.success) {
                showSuccessAlert(response?.message, Logo)
            } else if (response.error) {
                Swal.fire({
                    title: response.error,
                    text: response.error,
                    icon: 'error',
                });
            }

            console.log("useData?.user?.userType", authData?.user?.userType)

            if (togglePopup) {
                togglePopup(false)
            }

        } catch (error) {
            // Handle error
            console.error('Error updating member:', error);
            Swal.fire({
                title: error,
                text: error,
                icon: 'error',
            });
            setIsApiCall(false)


        } finally {
            setIsApiCall(false)
        }
    };
    // const onGerateQrCode = async () => {
    //     setIsloading(true)
    //     try {
    //         const qr = await genarateMatrimonySharebleQr({ domain: `${clientDomain}QR` })
    //         console.log(qr?.data?.qrCodeUrl)
    //         setQr(qr?.data?.qrCodeUrl)
    //     } catch (error) {

    //     } finally {
    //         // setIsloading(false)
    //     }
    // }

    const handleCheckboxChange = (vehicle, checked) => {
        setValue(vehicle, checked); // Assuming setValue is correctly defined somewhere

        setVehicale(prev => ({
            ...prev,
            [vehicle]: checked
        }));
    };

    const handleRadio = (home, checked) => {
        setValue(home, checked);
        setHome(checked)
    }

    const handleRadioHandicapped = (handicapped, checked) => {
        setValue(handicapped, checked);
        setHandicapped(checked)
    }


    return (
        <>
            <LoadingModal show={isApiCall} text={'updating...'} />
            <TermsAndConditions isOpen={isOpen} setIsOpen={setIsOpen} />
            {!(authData?.isLogin) && <HeaderBoarder heading={"विवाह रजिस्ट्रेशन "} />}
            {/* <div className=' w-52'><Calendar /></div> */}
            <ShowQrCode isOpen={isLoading} setIsOpen={setIsloading} img={qr} />

            <div className='w-100  md:px-10 bg-yellow-500 '>
                {!(authData?.isLogin) && <h1 className=' font-bold text-3xl text-red-700 text-center py-2'>पंजीकरण केवल महिलाओं के लिए स्वीकार्य है</h1>}
                {/* {authData?.isLogin && <div className=' w-full flex justify-end my-2'>
                    <button onClick={onGerateQrCode} className='btn bg-black text-white font-bold'>Generate QR</button>
                </div>} */}
                <form onSubmit={handleSubmit(adminEditData ? handleFromEditSubmit : handleFromSubmit)} className='border-[1px] border-gray-900/10 p-4'>
                    <div className="space-y-12 border-[1px]  border-gray-900/10 ">
                        <div className=" border-gray-900/10 pb-2">
                            <FormHeading heading={'Personal Information / व्यक्तिगत जानकारी'} />
                            <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-2 md:gap-y-4 sm:grid-cols-6 mx-2 md:mx-5">
                                {/* First Name */}
                                <div className="sm:col-span-3">
                                    <Input
                                        label="Full Name / पूरा नाम"
                                        placeholder="Full Name / पूरा नाम"
                                        type="text"
                                        required="required"
                                        {...register("name", {
                                            required: "इस फ़ील्ड को भरना ज़रूरी है",
                                            value: `${formData?.personalInformation?.name || ""}`,
                                            minLength: {
                                                value: 3,
                                                message: "कृपया कम से कम 3 अक्षर भरें"
                                            },
                                            maxLength: {
                                                value: 40,
                                                message: "कृपया 40 अक्षरों से कम भरें"
                                            }
                                        })}
                                        onChange={(e) => handleChange("name", e)}
                                    />
                                    {errors.firstName && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.firstName.message}</p>}
                                </div>
                                {/* surName */}

                                {/* samaj */}
                                <div className="sm:col-span-3">
                                    <SelectInput
                                        label="Samaj Name / समाज नाम"
                                        placeholder="Samaj Name / समाज नाम"
                                        options={vaishaSamaj}
                                        required="required"
                                        {...register("samaj", {
                                            value: `${formData?.personalInformation?.samaj || ""}`,
                                        }
                                        )}
                                        onChange={(e) => handleChange("samaj", e)}
                                    />
                                    {errors.samaj && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.samaj.message}</p>}
                                </div>

                                {/* gotra */}
                                <div className="sm:col-span-3">
                                    <SelectInput
                                        label="Your Gotra / आपकी गोत्र"
                                        placeholder="Your Gotra / आपकी गोत्र"
                                        options={gotra}
                                        required="required"
                                        alreadySelect={formData?.personalInformation?.gotra || ""}
                                        {...register("gotra", {
                                            value: `${formData?.personalInformation?.gotra || ""}`,
                                        }
                                        )}
                                        onChange={(e) => handleChange("gotra", e)}
                                    />
                                    {errors.gotra && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.gotra.message}</p>}
                                </div>


                                {/* skin color */}
                                <div className="sm:col-span-3">
                                    <SelectInput
                                        label="Skin Color / त्वचा का कलर "
                                        placeholder="Skin Color / त्वचा का कलर"
                                        options={skinColor}
                                        required="required"
                                        {...register("skinColor", {
                                            value: `${formData?.personalInformation?.skinColor || ""}`,
                                            required: "इस फ़ील्ड को भरना ज़रूरी है",
                                        }
                                        )}
                                        onChange={(e) => handleChange("skinColor", e)}
                                    />
                                    {errors.skinColor && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.skinColor.message}</p>}
                                </div>



                                {/* weight */}
                                <div className="sm:col-span-3">
                                    <Input
                                        label="Weight in KG / वजन"
                                        placeholder="Weight in KG / वजन"
                                        type="text"
                                        required="required"
                                        {...register("weightInKG", {
                                            required: "इस फ़ील्ड को भरना ज़रूरी है",
                                            value: `${formData?.personalInformation?.weightInKG || ""}`,
                                            minLength: {
                                                value: 2,
                                                message: "कृपया कम से कम 2 अंक भरें"
                                            },
                                            maxLength: {
                                                value: 3,
                                                message: "कृपया 3 अंक से अधिक न भरें"
                                            },
                                            pattern: {
                                                value: /^\d{1,3}$/, // Regular expression to match Indian mobile numbers
                                                message: "कृपया मान्य  नंबर दर्ज करें"
                                            }
                                        })}
                                        onChange={(e) => handleChange("weightInKG", e)}
                                    />
                                    {errors.weightInKG && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.weightInKG.message}</p>}
                                </div>

                                {/* height in feet */}
                                <div className="sm:col-span-3 h-[100%]  flex items-baseline ">
                                    <div className=" me-5">
                                        <Input
                                            label="Height Feet / लम्बाई फ़ीट"
                                            placeholder="Height Feet / लम्बाई फ़ीट"
                                            type="text"
                                            required="required"
                                            {...register("heightFeet", {
                                                required: "इस फ़ील्ड को भरना ज़रूरी है",
                                                value: `${formData?.personalInformation?.heightFeet || ""}`,
                                                minLength: {
                                                    value: 1,
                                                    message: "कृपया कम से कम 1 अंक भरें"
                                                },
                                                maxLength: {
                                                    value: 2,
                                                    message: "कृपया 2 अंक से अधिक न भरें"
                                                },
                                                pattern: {
                                                    value: /^\d{1,2}$/,
                                                    message: "कृपया मान्य  नंबर दर्ज करें"
                                                }
                                            })}
                                            onChange={(e) => handleChange("heightFeet", e)}
                                        />
                                        {errors.heightFeet && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.heightFeet.message}</p>}
                                    </div>

                                    {/* heightInch */}
                                    <div className="">
                                        <Input
                                            label="Height Inch / लम्बाई इंच"
                                            placeholder="Height Inch / लम्बाई इंच"
                                            type="text"
                                            required="required"
                                            {...register("heightInch", {
                                                required: "इस फ़ील्ड को भरना ज़रूरी है",
                                                value: `${formData?.personalInformation?.heightInch || ""}`,
                                                minLength: {
                                                    value: 1,
                                                    message: "कृपया कम से कम 1 अंक भरें"
                                                },
                                                maxLength: {
                                                    value: 2,
                                                    message: "कृपया 2 अंक से अधिक न भरें"
                                                },
                                                pattern: {
                                                    value: /^\d{1,2}$/, // Regular expression to match one or two-digit numbers
                                                    message: "कृपया मान्य संख्या दर्ज करें"
                                                }
                                            })}
                                            onChange={(e) => handleChange("heightInch", e)}
                                        />
                                        {errors.heightInch && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.heightInch.message}</p>}
                                    </div>

                                </div>

                                {/* Gender */}
                                <div className="sm:col-span-3">
                                    <SelectInput
                                        label="Gender / लिंग"
                                        placeholder="Gender / लिंग"
                                        options={["Male", "Female"]}
                                        required="required"
                                        {...register("gender", {
                                            value: `${formData?.personalInformation?.gender || ""}`,
                                        })}
                                        onChange={(e) => handleChange("gender", e)}
                                    // onChange={(e) => handleCheckboxChange("gender", e.target.checked)}
                                    />
                                    {errors.gender && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.gender.message}</p>}
                                </div>


                                {/* current MaritalStaus */}
                                <div className="sm:col-span-3 ">
                                    <SelectInput
                                        label="Current Marital Status / वैवाहिक स्थिति"
                                        placeholder="Current Marital Status / वैवाहिक स्थिति"
                                        options={currentMarritalStatus}
                                        required="required"
                                        {...register("currentMaritalStatus", {
                                            value: `${formData?.personalInformation?.currentMaritalStatus || ""}`,
                                        }
                                        )}
                                        onChange={(e) => handleMarritalStatus("maritalStatus", e)}
                                    />
                                    {errors.currentMaritalStatus && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.currentMaritalStatus.message}</p>}
                                </div>
                                {/* ***************************************************************** */}

                                {maritalStatus === "Divorcee" &&
                                    < >


                                        <div className="sm:col-span-3">
                                            <Input
                                                label="Divorce Date / तलाक की तारीख"
                                                placeholder="Divorce Date / तलाक की तारीख"
                                                type="date"

                                                onChange={(e) => handleChange(e)}
                                                {...register("divorceDate", {
                                                    // required: "इस फ़ील्ड को भरना ज़रूरी है",
                                                    value: `${formData.divorceDetails?.divorceDate?.split('T')[0] || ""}`,
                                                })}
                                            />
                                            {errors.divorceDate && <p id="donationDate-error" className="text-red-500 text-xs mt-1">{errors.divorceDate.message}</p>}
                                        </div>

                                        {/* divorce reason */}
                                        <div className="sm:col-span-3">
                                            <Input
                                                label="Divorce reason / तलाक का कारण"
                                                placeholder="Divorce reason / तलाक का कारण"
                                                type="text"

                                                {...register("divorceReason", {
                                                    // required: "इस फ़ील्ड को भरना ज़रूरी है",
                                                    value: `${formData?.divorceDetails?.divorceReason || ""}`,
                                                    minLength: {
                                                        value: 3,
                                                        message: "कृपया कम से कम 3 अक्षर भरें"
                                                    },
                                                    maxLength: {
                                                        value: 1000,
                                                        message: "कृपया 1000 अक्षरों से कम भरें"
                                                    }
                                                })}
                                                onChange={(e) => handleChange("divorceReason", e)}
                                            />
                                            {errors.divorceReason && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.divorceReason.message}</p>}
                                        </div>

                                        {/* Children1 */}
                                        <h1 className=' col-span-full text-xl font-semibold '>Children details / बच्चों का विवरण</h1>

                                        {formData?.divorceDetails?.childrenDetails?.length != 0 ?
                                            formData?.divorceDetails?.childrenDetails?.map((child, index) => {
                                                const num = index + 1;

                                                return (
                                                    <>
                                                        <div className="sm:col-span-3">
                                                            <SelectInput
                                                                label={`Children ${num} / बच्चा ${num}`}
                                                                placeholder={`Children ${num}`}
                                                                options={["Girl", "Boy"]}

                                                                {...register(`children${num}`, {
                                                                    value: child?.Children,
                                                                })}
                                                                onChange={(e) => handleChange(`children${num}`, e)}
                                                            />
                                                            {errors[`children${num}`] && (
                                                                <p id="username-error" className="text-red-500 text-xs mt-1">
                                                                    {errors[`children${num}`].message}
                                                                </p>
                                                            )}
                                                        </div>
                                                        {/* Children */}

                                                        {/* Children Age */}
                                                        <div className="sm:col-span-3">
                                                            <Input
                                                                label={`Children ${num} Age / बच्चा ${num} आयु`}
                                                                placeholder={`Children ${num} Age`}
                                                                type="text"

                                                                {...register(`children${num}Age`, {
                                                                    // required: "इस फ़ील्ड को भरना ज़रूरी है",
                                                                    value: `${child?.ChildrenAge}`,

                                                                    minLength: {
                                                                        value: 1,
                                                                        message: "कृपया कम से कम 1 अंक भरें",
                                                                    },
                                                                    maxLength: {
                                                                        value: 2,
                                                                        message: "कृपया 2 अंक से अधिक न भरें",
                                                                    },
                                                                    pattern: {
                                                                        value: /^\d{1,2}$/,
                                                                        message: "कृपया मान्य आयु दर्ज करें",
                                                                    },
                                                                })}
                                                                onChange={(e) => handleChange(`children${num}Age`, e)}
                                                            />
                                                            {errors[`children${num}Age`] && (
                                                                <p id="username-error" className="text-red-500 text-xs mt-1">
                                                                    {errors[`children${num}Age`].message}
                                                                </p>
                                                            )}
                                                        </div>
                                                    </>
                                                );
                                            })
                                            : <>
                                                <div className="sm:col-span-3">
                                                    <SelectInput
                                                        label="Children 1 / बच्चा 1"
                                                        placeholder="Children 1"
                                                        options={["Girl", "Boy"]}
                                                        {...register("children1", {
                                                        })}
                                                        onChange={(e) => handleChange("children1", e)}
                                                    // onChange={(e) => handleCheckboxChange("gender", e.target.checked)}
                                                    />
                                                    {errors.children1 && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.children1.message}</p>}
                                                </div>
                                                <div className="sm:col-span-3">
                                                    <Input
                                                        label="Children 1 Age / बच्चा 1 आयु"
                                                        placeholder="Children 1 Age"
                                                        type="text"
                                                        {...register("children1Age", {
                                                            // required: "इस फ़ील्ड को भरना ज़रूरी है",
                                                            minLength: {
                                                                value: 1,
                                                                message: "कृपया कम से कम 1 अंक भरें"
                                                            },
                                                            maxLength: {
                                                                value: 2,
                                                                message: "कृपया 2 अंक से अधिक न भरें"
                                                            },
                                                            pattern: {
                                                                value: /^\d{1,2}$/, // Regular expression to match one or two-digit numbers
                                                                message: "कृपया मान्य आयु दर्ज करें"
                                                            }
                                                        })}
                                                        onChange={(e) => handleChange("children1Age", e)}
                                                    />
                                                    {errors.children1Age && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.children1Age.message}</p>}
                                                </div>

                                                <div className="sm:col-span-3">
                                                    <SelectInput
                                                        label="Children 2 / बच्चा 2"
                                                        placeholder="Children 2"
                                                        options={["Girl", "Boy"]}
                                                        {...register("children2")}
                                                        onChange={(e) => handleChange("children2", e)}
                                                    // onChange={(e) => handleCheckboxChange("gender", e.target.checked)}
                                                    />
                                                    {errors.children2 && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.children2.message}</p>}
                                                </div>

                                                {/* Children2 Age */}
                                                <div className="sm:col-span-3">
                                                    <Input
                                                        label="Children 2 Age / बच्चा 2 आयु"
                                                        placeholder="Children 2 Age"
                                                        type="text"
                                                        {...register("children2Age", {
                                                            // required: "इस फ़ील्ड को भरना ज़रूरी है",
                                                            minLength: {
                                                                value: 1,
                                                                message: "कृपया कम से कम 1 अंक भरें"
                                                            },
                                                            maxLength: {
                                                                value: 2,
                                                                message: "कृपया 2 अंक से अधिक न भरें"
                                                            },
                                                            pattern: {
                                                                value: /^\d{1,2}$/, // Regular expression to match one or two-digit numbers
                                                                message: "कृपया मान्य आयु दर्ज करें"
                                                            }
                                                        })}
                                                        onChange={(e) => handleChange("children2Age", e)}
                                                    />
                                                    {errors.children2Age && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.children2Age.message}</p>}
                                                </div>


                                                <div className="sm:col-span-3">
                                                    <SelectInput
                                                        label="Children 3 / बच्चा 3"
                                                        placeholder="Children 3"
                                                        options={["Girl", "Boy"]}
                                                        {...register("children3")}
                                                        onChange={(e) => handleChange("children3", e)}
                                                    // onChange={(e) => handleCheckboxChange("gender", e.target.checked)}
                                                    />
                                                    {errors.children3 && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.children3.message}</p>}
                                                </div>

                                                {/* Children3 Age */}
                                                <div className="sm:col-span-3">
                                                    <Input
                                                        label="Children 3 Age / बच्चा 3 आयु"
                                                        placeholder="Children 3 Age"
                                                        type="text"
                                                        {...register("children3Age", {
                                                            // required: "इस फ़ील्ड को भरना ज़रूरी है",
                                                            minLength: {
                                                                value: 1,
                                                                message: "कृपया कम से कम 1 अंक भरें"
                                                            },
                                                            maxLength: {
                                                                value: 2,
                                                                message: "कृपया 2 अंक से अधिक न भरें"
                                                            },
                                                            pattern: {
                                                                value: /^\d{1,2}$/, // Regular expression to match one or two-digit numbers
                                                                message: "कृपया मान्य आयु दर्ज करें"
                                                            }
                                                        })}
                                                        onChange={(e) => handleChange("children3Age", e)}
                                                    />
                                                    {errors.children3Age && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.children3Age.message}</p>}
                                                </div></>
                                        }
                                    </>
                                }
                                {/* ************************************************************************                              */}

                                <div className="col-span-full">
                                    <Textarea
                                        label="Current Address / वर्त्तमान पता"
                                        placeholder="Current Address / वर्त्तमान पता"
                                        type="text"
                                        required="required"
                                        {...register("currentAddress", {
                                            required: "इस फ़ील्ड को भरना ज़रूरी है",
                                            value: `${formData?.personalInformation?.currentAddress}`,

                                            minLength: {
                                                value: 10,
                                                message: "कृपया कम से कम 10 अक्षर भरें"
                                            },
                                            maxLength: {
                                                value: 100,
                                                message: "कृपया 100 अक्षरों से कम भरें"
                                            }
                                        })}
                                        onChange={(e) => handleChange("currentAddress", e)}
                                    />
                                    {errors.currentAddress && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.currentAddress.message}</p>}

                                </div>
                                {/* currentAddress city */}

                                <div className="sm:col-span-3">
                                    <CityDropDown
                                        options={cityOptions}
                                        setCity={setCityOptions}
                                        search={'city'}
                                        id={'currentCity'}
                                        setOptions={setCurrentCity}
                                        required="required"
                                        label={"Current city / वर्तमान शहर"}
                                        className={' w-full h-9 px-4 py-2 border  border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                                        style={' text-black font-bold'}
                                        alredySetCity={currentCity}
                                    />
                                </div>

                                <div className="sm:col-span-3">
                                    <Input
                                        label="Pincode / पिनकोड"
                                        placeholder="Pincode / पिनकोड"
                                        type="text"
                                        required="required"
                                        {...register("currentPincode", {
                                            required: "इस फ़ील्ड को भरना ज़रूरी है",
                                            value: `${formData?.personalInformation?.currentPincode}`,
                                            minLength: {
                                                value: 6,
                                                message: "कृपया कम से कम 6 अंक भरें"
                                            },
                                            maxLength: {
                                                value: 6,
                                                message: "कृपया 6 अंक से अधिक न भरें"
                                            },
                                            pattern: {
                                                value: /^[0-9]\d{5}$/, // Regular expression to match Indian mobile numbers
                                                message: "कृपया मान्य पिनकोड नंबर दर्ज करें"
                                            }
                                        })}
                                        onChange={(e) => handleChange("currentPincode", e)}
                                    />
                                    {errors.currentPincode && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.currentPincode.message}</p>}
                                </div>

                            </div>
                            <FormHeading heading={'kundli details / कुंडली  जानकारी '} />
                            {/* Date of birth/time------compulsaryExact
                             age per date of birth----Auto calculate by
                              date of 
                              birthPlace of birth------compulsary
                              Rashi by birth------compulsary
                              Rashi by date of birth------Not 
                              compulsary
                              Mangalik - yes or no------compulsary */}
                            {/* Education qualification=-------500
                               charactersOccupation details :
                               Occupation - self business ,family business ,private job ,govt. Job ,other------compulsary
                               Company Name - designation city
                                average salary per month------Not compulsary */}
                            {/* permanentAddress */}
                            <div className=" border-gray-900/10 pb-2">
                                <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 mx-2 md:mx-5">
                                    {/* Date of birth/time */}
                                    <div className="sm:col-span-3">
                                        <Input
                                            label="Birth Date Time / जन्म दिनांक-टाइम"
                                            placeholder="Birth Date Time / जन्म दिनांक-टाइम"
                                            required="required"
                                            type="datetime-local"
                                            defaultValue={
                                                formData?.kundliDetails?.birthDateTime
                                                    ? new Date(formData.kundliDetails.birthDateTime).toISOString().slice(0, 16)
                                                    : ""
                                            }
                                            {...register("birthDateTime", {
                                                required: "इस फ़ील्ड को भरना ज़रूरी है",
                                            })}
                                            onChange={(e) => calculateAgeFromDateTime(e)}
                                        />
                                        {errors.birthDateTime && (
                                            <p id="dharmashala-error" className="text-red-500 text-xs mt-1">
                                                {errors.birthDateTime.message}
                                            </p>
                                        )}
                                    </div>


                                    {/* Age */}
                                    <div className="sm:col-span-3">
                                        <Input
                                            label="Age / उम्र"
                                            placeholder="Age / उम्र"
                                            required="required"
                                            type="text"
                                            value={age}
                                            disabled={true}
                                        />
                                        {/* {errors.age && <p id="dharmashala-error" className="text-red-500 text-xs mt-1">{errors.age.message}</p>} */}
                                    </div>

                                    {/* birthPlace of birth */}
                                    <div className="sm:col-span-3">
                                        <Input
                                            label="Birth Place / जन्म स्थान"
                                            placeholder="Birth Place / जन्म स्थान"
                                            type="text"
                                            required="required"

                                            {...register("birthPlace", {
                                                required: "इस फ़ील्ड को भरना ज़रूरी है",
                                                value: `${formData?.kundliDetails?.birthPlace || ""}`,
                                                minLength: {
                                                    value: 3,
                                                    message: "कृपया कम से कम 3 अक्षर भरें"
                                                },
                                                maxLength: {
                                                    value: 40,
                                                    message: "कृपया 40 अक्षरों से कम भरें"
                                                }
                                            })}
                                            onChange={(e) => handleChange("birthPlace", e)}
                                        />
                                        {errors.birthPlace && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.birthPlace.message}</p>}
                                    </div>

                                    {/* Mangalik */}
                                    <div className="sm:col-span-3">
                                        <SelectInput
                                            label="Mangalik / मांगलिक"
                                            placeholder="Mangalik"
                                            options={['हाँ', 'नहीं', 'पतानहीं', 'आंशिक']}
                                            required="required"
                                            {...register("mangalik", {
                                                value: `${formData?.kundliDetails?.mangalik || ""}`,
                                            })}
                                            onChange={(e) => handleChange("mangalik", e)}
                                        // onChange={(e) => handleCheckboxChange("gender", e.target.checked)}
                                        />
                                        {errors.mangalik && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.mangalik.message}</p>}
                                    </div>
                                </div>
                            </div>

                            <FormHeading heading={'Education qualification / शैक्षणिक योग्यता'} />
                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mx-5">
                                {/* Education qualification */}
                                <div className="col-span-full  ">
                                    <Textarea
                                        label="Education Qualification / शैक्षणिक योग्यता"
                                        placeholder="Education Qualification / शैक्षणिक योग्यता"
                                        required="required"
                                        type="text"
                                        {...register("educationQualification", {
                                            required: "इस फ़ील्ड को भरना ज़रूरी है",
                                            value: `${formData?.educationQualification || ""}`,
                                            minLength: {
                                                value: 3,
                                                message: "कृपया कम से कम 3 अक्षर भरें"
                                            },
                                            maxLength: {
                                                value: 500,
                                                message: "कृपया 500 अक्षरों से कम भरें"
                                            }
                                        })}
                                        onChange={(e) => handleChange("educationQualification", e)}
                                    />
                                    {errors.educationQualification && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.educationQualification.message}</p>}

                                </div>
                            </div>
                            <FormHeading heading={'Occupation Details / व्यवसाय विवरण'} />
                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mx-5">
                                {/* Occupation */}
                                <div className="sm:col-span-3">
                                    <SelectInput
                                        label="Occupation / व्यवसाय"
                                        placeholder="Occupation"
                                        options={occupations}
                                        required="required"
                                        {...register("occupation", {
                                            value: `${formData?.occupationDetails?.occupation || ""}`,

                                        })}
                                        onChange={(e) => handleChange("occupation", e)}
                                    // onChange={(e) => handleCheckboxChange("gender", e.target.checked)}
                                    />
                                    {errors.occupation && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.occupation.message}</p>}
                                </div>

                                {/* Company Name */}
                                <div className="sm:col-span-3">
                                    <Input
                                        label="Company Name / कंपनी का नाम"
                                        placeholder="Company Name / कंपनी का नाम"
                                        type="text"
                                        {...register("companyName", {
                                            value: `${formData?.occupationDetails?.companyName || ""}`,
                                            // value: `${formData?.name}`,
                                            minLength: {
                                                value: 3,
                                                message: "कृपया कम से कम 3 अक्षर भरें"
                                            },
                                            maxLength: {
                                                value: 40,
                                                message: "कृपया 40 अक्षरों से कम भरें"
                                            }
                                        })}
                                        onChange={(e) => handleChange("companyName", e)}
                                    />
                                    {errors.companyName && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.companyName.message}</p>}
                                </div>

                                {/* designation */}
                                <div className="sm:col-span-3">
                                    <Input
                                        label="Designation / कंपनी मैं पद"
                                        placeholder="Designation / कंपनी मैं पद"
                                        type="text"
                                        {...register("designation", {
                                            value: `${formData?.occupationDetails?.designation || ""}`,
                                            minLength: {
                                                value: 3,
                                                message: "कृपया कम से कम 3 अक्षर भरें"
                                            },
                                            maxLength: {
                                                value: 40,
                                                message: "कृपया 40 अक्षरों से कम भरें"
                                            }
                                        })}
                                        onChange={(e) => handleChange("designation", e)}
                                    />
                                    {errors.designation && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.designation.message}</p>}
                                </div>

                                {/* city */}
                                {/* <div className="sm:col-span-3">
                                    <CityDropDown
                                        options={cityOptions}
                                        setCity={setCityOptions}
                                        search={'city'}
                                        id={'occupationCity'}
                                        // required="required"
                                        setOptions={setOccupationCity}
                                        label={"occupation city / कंपनी का शहर"}
                                        className={' w-full  h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                                        style={' text-black font-bold'}
                                        alredySetCity={occupationCity}
                                    />
                                </div> */}


                                <div className="sm:col-span-3">
                                    <Input
                                        label="occupation city / कंपनी का शहर"
                                        placeholder="occupation city / कंपनी का शहर"
                                        type="text"
                                        {...register("occupationCityText", {
                                            value: `${formData?.occupationDetails?.occupationCityText || ""}`,
                                            minLength: {
                                                value: 3,
                                                message: "कृपया कम से कम 3 अक्षर भरें"
                                            },
                                            maxLength: {
                                                value: 40,
                                                message: "कृपया 40 अक्षरों से कम भरें"
                                            }
                                        })}
                                        onChange={(e) => handleChange("occupationCityText", e)}
                                    />
                                    {errors.occupationCityText && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.occupationCityText.message}</p>}
                                </div>



                                {/* average salary per month */}
                                <div className="sm:col-span-3">
                                    <Input
                                        label="average salary per month / महीने की सैलरी"
                                        placeholder="Average salary per month"
                                        type="text"
                                        {...register("averageSalaryPerMonth", {
                                            value: `${formData?.occupationDetails?.averageSalaryPerMonth || ""}`,
                                            minLength: {
                                                value: 1,
                                                message: "कृपया कम से कम 10 अंक भरें"
                                            },
                                            maxLength: {
                                                value: 10,
                                                message: "कृपया 10 अंक से अधिक न भरें"
                                            },
                                            pattern: {
                                                value: /\d/, // Regular expression to match Indian mobile numbers
                                                message: "कृपया मान्य सैलरी दर्ज करें"
                                            }
                                        })}
                                        onChange={(e) => handleChange("averageSalaryPerMonth", e)}
                                    />
                                    {errors.averageSalaryPerMonth && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.averageSalaryPerMonth.message}</p>}
                                </div>
                            </div>
                            <FormHeading heading={'Contact Details / संपर्क  जानकारी'} />

                            {/* Contact Details :
                            Mobile number------compulsary
                            WhatsApp number------compulsary
                            Facebook id------Not compulsary
                            LinkedIn id------Not compulsary
                            Instagram ID------Not compulsary
                            email ID--------not compulsary
                            Hobbies------Not compulsary */}
                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mx-5">
                                {/* mobile number */}
                                <div className="sm:col-span-3">
                                    <Input
                                        label="Mobile no / मोबाइल नंबर      (will be login number)"
                                        placeholder="Mobile no / मोबाइल नंबर"
                                        type="number"
                                        required="required"
                                        disabled={(formData?.contactDetails?.mobileNo && useData?.user?.userType !== 1)}
                                        {...register("mobileNo", {
                                            required: "इस फ़ील्ड को भरना ज़रूरी है",
                                            value: `${formData?.contactDetails?.mobileNo || ""}`,
                                            minLength: {
                                                value: 10,
                                                message: "कृपया कम से कम 10 अंक भरें"
                                            },
                                            maxLength: {
                                                value: 10,
                                                message: "कृपया 10 अंक से अधिक न भरें"
                                            },
                                            pattern: {
                                                value: /^[6-9]\d{9}$/, // Regular expression to match Indian mobile numbers
                                                message: "कृपया मान्य मोबाइल नंबर दर्ज करें"
                                            }
                                        })}
                                        onChange={(e) => handleRegisterMobileNoChange(e)}
                                    />
                                    {errors.mobileNo && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.mobileNo.message}</p>}
                                    {isMoblieNumberAlreadyExist && <span className=' animate-ping  w-100 h-100'>🔃 checking</span>}
                                    {mobileNumberCheckLoading && <small className=' text-green-700   w-100 h-100'> ✔ Valid Mobile Number</small>}

                                </div>

                                {/* whatsappNo */}
                                <div className="sm:col-span-3">
                                    <Input
                                        label="Whatsapp No / व्हाट्सएप नंबर"
                                        placeholder="Whatsapp No / व्हाट्सएप नंबर"
                                        type="number"
                                        required="required"
                                        {...register("whatsappNo", {
                                            required: "इस फ़ील्ड को भरना ज़रूरी है",
                                            value: `${formData?.contactDetails?.whatsappNo || ""}`,
                                            minLength: {
                                                value: 10,
                                                message: "कृपया कम से कम 10 अंक भरें"
                                            },
                                            maxLength: {
                                                value: 10,
                                                message: "कृपया 10 अंक से अधिक न भरें"
                                            },
                                            pattern: {
                                                value: /^[6-9]\d{9}$/, // Regular expression to match Indian mobile numbers
                                                message: "कृपया मान्य मोबाइल नंबर दर्ज करें"
                                            }
                                        })}
                                        onChange={(e) => handleChange("whatsappNo", e)}
                                    />
                                    {errors.whatsappNo && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.whatsappNo.message}</p>}
                                </div>

                                {/* email */}
                                <div className="sm:col-span-3">
                                    <Input
                                        label="Email id / ईमेल आईडी"
                                        placeholder="Email id / ईमेल आईडी"
                                        type="email" // Change type to "email" for email validation
                                        // required="required"
                                        {...register("emailId", {

                                            value: `${formData?.contactDetails?.emailId || ""}`,
                                            minLength: {
                                                value: 3,
                                                message: "कृपया कम से कम 3 अक्षर भरें"
                                            },
                                            // maxLength: {
                                            //     value: 20,
                                            //     message: "कृपया 20 अक्षरों से कम भरें"
                                            // },
                                            pattern: {
                                                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, // Regular expression for email validation
                                                message: "कृपया मान्य ईमेल पता दर्ज करें"
                                            }
                                        })}
                                        onChange={(e) => handleChange("emailId", e)}
                                    />
                                    {errors.emailId && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.emailId.message}</p>}
                                </div>
                            </div>

                        </div>
                        <FormHeading heading={'Family Detail / पारिवारिक विवरण'} />
                        {/* Family details */}
                        {/* -father's name ,
                        occupation,
                        mobile number,
                        photo , ---compulsary
                        Mother name,
                        occupation,
                        mobile no,
                        photo---compulsary
                        brother -  count, married or unmarried---compulsary
                        sister - count, married or unmarried---compulsary
                        Residing with family - yes or no---compulsary
                        Current family type - joint , nuclear , independent,single---compulsary
                        interested in family type- joint, single , independent---compulsary */}

                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mx-5">
                            <div className="col-span-full  ">
                                <Textarea
                                    label="Father's Occupation / पिताजी का व्यवसाय"
                                    placeholder="Father's Occupation / पिताजी का व्यवसाय"
                                    type="text"
                                    {...register("fatherOccupation", {
                                        value: `${formData?.familyDetail?.fatherOccupation || ""}`,
                                    })}
                                    onChange={(e) => handleChange("fatherOccupation", e)}
                                />
                                {errors.fatherOccupation && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.fatherOccupation.message}</p>}
                            </div>
                            {/* fatherName */}
                            <div className="sm:col-span-3">
                                <Input
                                    label="Father's Name / पिताजी का नाम"
                                    placeholder="Father's Name / पिताजी का नाम"
                                    type="text"

                                    required="required"
                                    {...register("fatherName", {
                                        required: "इस फ़ील्ड को भरना ज़रूरी है",
                                        value: `${formData?.familyDetail?.fatherName || ""}`,
                                        minLength: {
                                            value: 3,
                                            message: "कृपया कम से कम 3 अक्षर भरें"
                                        },
                                        maxLength: {
                                            value: 100,
                                            message: "कृपया 100 अक्षरों से कम भरें"
                                        }
                                    })}
                                    onChange={(e) => handleChange("fatherName", e)}
                                />
                                {errors.fatherName && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.fatherName.message}</p>}
                            </div>

                            {/* father Occupation */}
                            {/* <div className="sm:col-span-3">
                                <SelectInput
                                    label="Father's Occupation / पिताजी का व्यवसाय"
                                    placeholder="Father's Occupation / पिताजी का व्यवसाय"
                                    options={occupations}
                                    {...register("fatherOccupation", {
                                        value: `${formData?.familyDetail?.fatherOccupation}`,
                                    })}
                                    onChange={(e) => handleChange("fatherOccupation", e)}
                                // onChange={(e) => handleCheckboxChange("gender", e.target.checked)}
                                />
                                {errors.fatherOccupation && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.fatherOccupation.message}</p>}
                            </div> */}


                            {/* father number */}
                            <div className="sm:col-span-3">
                                <Input
                                    label="Father's Mobile no / पिता का मोबाइल नंबर"
                                    placeholder="Father's Mobile no / पिता का मोबाइल नंबर"
                                    type="number"
                                    // required="required"
                                    {...register("fatherMobileNo", {
                                        // required: "इस फ़ील्ड को भरना ज़रूरी है",
                                        value: `${formData?.familyDetail?.fatherMobileNo || ""}`,
                                        minLength: {
                                            value: 10,
                                            message: "कृपया कम से कम 10 अंक भरें"
                                        },
                                        maxLength: {
                                            value: 10,
                                            message: "कृपया 10 अंक से अधिक न भरें"
                                        },
                                        pattern: {
                                            value: /^[6-9]\d{9}$/, // Regular expression to match Indian mobile numbers
                                            message: "कृपया मान्य मोबाइल नंबर दर्ज करें"
                                        }
                                    })}
                                    onChange={(e) => handleChange("fatherMobileNo", e)}
                                />
                                {errors.fatherMobileNo && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.fatherMobileNo.message}</p>}
                            </div>
                            <div className="sm:col-span-3">
                                <SelectInput
                                    label="father mama gotra / पिता की मामा गोत्र"
                                    placeholder="Gotra"
                                    options={['पतानहीं', ...gotra]}
                                    alreadySelect={formData?.familyDetail?.fatherMamaGotra || ""}

                                    {...register("fatherMamaGotra", {
                                        value: `${formData?.familyDetail?.fatherMamaGotra || ""}`,
                                    }
                                    )}
                                    onChange={(e) => handleChange("fatherMamaGotra", e)}
                                />
                                {errors.fatherMamaGotra && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.fatherMamaGotra.message}</p>}
                            </div>

                            {/* mother Name */}
                            <div className="sm:col-span-3">
                                <Input
                                    label="Mother's Name / माँ का नाम"
                                    placeholder="Mother's Name / माँ का नाम"
                                    type="text"
                                    required="required"
                                    {...register("motherName", {
                                        required: "इस फ़ील्ड को भरना ज़रूरी है",
                                        value: `${formData?.familyDetail?.motherName || ""}`,
                                        minLength: {
                                            value: 3,
                                            message: "कृपया कम से कम 3 अक्षर भरें"
                                        },
                                        maxLength: {
                                            value: 100,
                                            message: "कृपया 100 अक्षरों से कम भरें"
                                        }
                                    })}
                                    onChange={(e) => handleChange("motherName", e)}
                                />
                                {errors.motherName && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.motherName.message}</p>}
                            </div>
                            <div className="sm:col-span-3">
                                <SelectInput
                                    label="Mother's Mama Gotra / माता की मामा का गोत्र"
                                    placeholder="Gotra"
                                    options={['पतानहीं', ...gotra]}
                                    alreadySelect={formData?.familyDetail?.motherMamaGotra || ""}
                                    {...register("motherMamaGotra", {
                                        value: `${formData?.familyDetail?.motherMamaGotra || ""}`,
                                    }
                                    )}
                                    onChange={(e) => handleChange("motherMamaGotra", e)}
                                />
                                {errors.motherMamaGotra && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.motherMamaGotra.message}</p>}
                            </div>


                            {/* brother */}
                            <div className="sm:col-span-3">
                                <Input
                                    label="Total Brother / भाई"
                                    placeholder="Total Brothers / भाई"
                                    type="text"
                                    required="required"
                                    {...register("brother", {
                                        required: "इस फ़ील्ड को भरना ज़रूरी है",
                                        value: `${formData?.familyDetail?.brother}`,
                                        minLength: {
                                            value: 0,
                                            message: "कृपया कम से कम 0 अंक भरें"
                                        },
                                        maxLength: {
                                            value: 1,
                                            message: "कृपया 1 अंक से अधिक न भरें"
                                        },
                                        pattern: {
                                            value: /^\d{1}$/, // Regular expression to match Indian mobile numbers
                                            message: "कृपया मान्य अंक दर्ज करें"
                                        }


                                    })}
                                    onChange={(e) => handleChange("brother", e)}
                                />
                                {errors.brother && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.brother.message}</p>}
                            </div>
                            {/* marriedBrother */}
                            <div className="sm:col-span-3">
                                <Input
                                    label="Married Brother / शादीशुदा भाई"
                                    placeholder="Married Brother / शादीशुदा भाई"
                                    type="text"
                                    required="required"
                                    {...register("marriedBrother", {
                                        required: "इस फ़ील्ड को भरना ज़रूरी है",
                                        value: `${formData?.familyDetail?.marriedBrother}`,
                                        minLength: {
                                            value: 0,
                                            message: "कृपया कम से कम 0 अंक भरें"
                                        },
                                        maxLength: {
                                            value: 1,
                                            message: "कृपया 1 अंक से अधिक न भरें"
                                        },
                                        pattern: {
                                            value: /^\d{1}$/, // Regular expression to match Indian mobile numbers
                                            message: "कृपया मान्य अंक दर्ज करें"
                                        }
                                    })}
                                    onChange={(e) => handleChange("marriedBrother", e)}
                                />
                                {errors.marriedBrother && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.marriedBrother.message}</p>}
                            </div>

                            {/* unmarriedBrother */}
                            <div className="sm:col-span-3">
                                <Input
                                    label="Unmarried Brother / अविवाहित भाई"
                                    placeholder="Unmarried Brother / अविवाहित भाई"
                                    type="text"
                                    required="required"
                                    {...register("unmarriedBrother", {
                                        required: "इस फ़ील्ड को भरना ज़रूरी है",
                                        value: `${formData?.familyDetail?.unmarriedBrother}`,
                                        minLength: {
                                            value: 0,
                                            message: "कृपया कम से कम 0 अंक भरें"
                                        },
                                        maxLength: {
                                            value: 1,
                                            message: "कृपया 1 अंक से अधिक न भरें"
                                        },
                                        pattern: {
                                            value: /^\d{1}$/, // Regular expression to match Indian mobile numbers
                                            message: "कृपया मान्य अंक दर्ज करें"
                                        }
                                    })}
                                    onChange={(e) => handleChange("unmarriedBrother", e)}
                                />
                                {errors.unmarriedBrother && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.unmarriedBrother.message}</p>}
                            </div>



                            {/* sister */}
                            <div className="sm:col-span-3">
                                <Input
                                    label="Total Sisters / कूल बहने"
                                    placeholder="Sister"
                                    type="text"
                                    required="required"
                                    {...register("sisters", {
                                        required: "इस फ़ील्ड को भरना ज़रूरी है",
                                        value: `${formData?.familyDetail?.sisters}`,
                                        minLength: {
                                            value: 0,
                                            message: "कृपया कम से कम 0 अंक भरें"
                                        },
                                        maxLength: {
                                            value: 1,
                                            message: "कृपया 1 अंक से अधिक न भरें"
                                        },
                                        pattern: {
                                            value: /^\d{1}$/, // Regular expression to match Indian mobile numbers
                                            message: "कृपया मान्य अंक दर्ज करें"
                                        }


                                    })}
                                    onChange={(e) => handleChange("sisters", e)}
                                />
                                {errors.sisters && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.sisters.message}</p>}
                            </div>
                            {/* marriedBrother */}
                            <div className="sm:col-span-3">
                                <Input
                                    label="Married Sister / विवाहित बहन"
                                    placeholder="Married Sister"
                                    type="text"
                                    required="required"
                                    {...register("marriedSister", {
                                        required: "इस फ़ील्ड को भरना ज़रूरी है",
                                        value: `${formData?.familyDetail?.marriedSister}`,
                                        minLength: {
                                            value: 0,
                                            message: "कृपया कम से कम 0 अंक भरें"
                                        },
                                        maxLength: {
                                            value: 1,
                                            message: "कृपया 1 अंक से अधिक न भरें"
                                        },
                                        pattern: {
                                            value: /^\d{1}$/, // Regular expression to match Indian mobile numbers
                                            message: "कृपया मान्य अंक दर्ज करें"
                                        }
                                    })}
                                    onChange={(e) => handleChange("marriedSister", e)}
                                />
                                {errors.marriedSister && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.marriedSister.message}</p>}
                            </div>
                            {/* unmarriedBrother */}
                            <div className="sm:col-span-3">
                                <Input
                                    label="Unmarried Sister / अविवाहित बहन"
                                    placeholder="Unmarried Sister"
                                    type="text"
                                    required="required"
                                    {...register("unmarriedSister", {
                                        required: "इस फ़ील्ड को भरना ज़रूरी है",
                                        value: `${formData?.familyDetail?.unmarriedSister}`,
                                        minLength: {
                                            value: 0,
                                            message: "कृपया कम से कम 0 अंक भरें"
                                        },
                                        maxLength: {
                                            value: 1,
                                            message: "कृपया 1 अंक से अधिक न भरें"
                                        },
                                        pattern: {
                                            value: /^\d{1}$/, // Regular expression to match Indian mobile numbers
                                            message: "कृपया मान्य अंक दर्ज करें"
                                        }
                                    })}
                                    onChange={(e) => handleChange("unmarriedSister", e)}
                                />
                                {errors.unmarriedSister && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.unmarriedSister.message}</p>}
                            </div>
                        </div>

                        <FormHeading heading={'Mama details / मामा की जानकारी '} />
                        {/* 
                        Mama details -
                        mama name-----compulsary
                         occupation---compulsary
                         photo---compulsary
                         City---compulsary
                         gotra---compulsary */}

                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mx-5">
                            {/*Mama name */}
                            <div className="sm:col-span-3">
                                <Input
                                    label="Mama name / मामा का नाम"
                                    placeholder="Mama name"
                                    type="text"
                                    required="required"
                                    {...register("mamaName", {
                                        required: "इस फ़ील्ड को भरना ज़रूरी है",
                                        value: `${formData?.mamaDetails?.mamaName || ""}`,
                                        minLength: {
                                            value: 3,
                                            message: "कृपया कम से कम 3 अक्षर भरें"
                                        },
                                        maxLength: {
                                            value: 40,
                                            message: "कृपया 40 अक्षरों से कम भरें"
                                        }
                                    })}
                                    onChange={(e) => handleChange("mamaName", e)}
                                />
                                {errors.mamaName && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.mamaName.message}</p>}
                            </div>
                            {/*Mama gotra */}
                            <div className="sm:col-span-3">
                                <SelectInput
                                    label="Mama gotra / मामा की गोत्र"
                                    placeholder="Mama gotra"
                                    options={['पतानहीं', ...gotra]}
                                    required="required"
                                    alreadySelect={formData?.mamaDetails?.mamaGotra || ""}
                                    {...register("mamaGotra", {
                                        required: "इस फ़ील्ड को भरना ज़रूरी है",
                                        value: `${formData?.mamaDetails?.mamaGotra || ""}`,
                                    }
                                    )}
                                    onChange={(e) => handleChange("mamaGotra", e)}
                                />
                                {errors.mamaGotra && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.mamaGotra.message}</p>}
                            </div>
                        </div>

                        <FormHeading heading={'Samaj Reference details / समाज मैं रिफरेन्स'} />
                        {/* Reference - 1 -
                         name, 
                         city , 
                         occupation , 
                         mobile number,
                          relation------compulsary
                        Reference -  2 - name, city , occupation , mobile number, relation------compulsary */}
                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mx-5">
                            <h1 className=' col-span-full text-xl font-semibold '>Reference - 1 / रिफरेन्स .1</h1>
                            {/*Mama name */}
                            <div className="sm:col-span-3">
                                <Input
                                    label="Name / नाम "
                                    placeholder="Name"
                                    type="text"
                                    // required="required"

                                    {...register("reference1Name", {
                                        // required: "इस फ़ील्ड को भरना ज़रूरी है",
                                        value: `${formData?.samajReferenceDetails?.reference1Name || ""}`,

                                        minLength: {
                                            value: 3,
                                            message: "कृपया कम से कम 3 अक्षर भरें"
                                        },
                                        maxLength: {
                                            value: 40,
                                            message: "कृपया 40 अक्षरों से कम भरें"
                                        }
                                    })}
                                    onChange={(e) => handleChange("reference1Name", e)}
                                />
                                {errors.reference1Name && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.reference1Name.message}</p>}
                            </div>

                            {/* Reference - 1 city */}

                            <div className="sm:col-span-3">
                                <CityDropDown
                                    options={cityOptions}
                                    setCity={setCityOptions}
                                    search={'city'}
                                    id={'Reference1city'}
                                    // required="required"
                                    setOptions={setReference1City}
                                    label={"Reference 1 city / रेफरेंस 1 शहर"}
                                    className={' w-full  h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500'}
                                    style={' text-black font-bold'}
                                    alredySetCity={reference1City}
                                />
                            </div>

                            {/* Reference - 1 Occupation */}
                            <div className="sm:col-span-3">
                                <SelectInput
                                    label="Occupation / व्यवसाय"
                                    placeholder="Occupation"
                                    options={occupations}
                                    // required="required"
                                    {...register("reference1Occupation", {
                                        value: `${formData?.samajReferenceDetails?.reference1Occupation || ""}`,
                                    })}
                                    onChange={(e) => handleChange("reference1Occupation", e)}
                                // onChange={(e) => handleCheckboxChange("gender", e.target.checked)}
                                />
                                {errors.reference1Occupation && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.reference1Occupation.message}</p>}
                            </div>

                            {/* reference1 number */}
                            <div className="sm:col-span-3">
                                <Input
                                    label="Mobile no / मोबाइल नंबर"
                                    placeholder="Mobile no"
                                    type="number"
                                    // required="required"
                                    {...register("reference1MobileNo", {
                                        // required: "इस फ़ील्ड को भरना ज़रूरी है",
                                        value: `${formData?.samajReferenceDetails?.reference1MobileNo || ""}`,
                                        minLength: {
                                            value: 10,
                                            message: "कृपया कम से कम 10 अंक भरें"
                                        },
                                        maxLength: {
                                            value: 10,
                                            message: "कृपया 10 अंक से अधिक न भरें"
                                        },
                                        pattern: {
                                            value: /^[6-9]\d{9}$/, // Regular expression to match Indian mobile numbers
                                            message: "कृपया मान्य मोबाइल नंबर दर्ज करें"
                                        }
                                    })}
                                    onChange={(e) => handleChange("reference1MobileNo", e)}
                                />
                                {errors.reference1MobileNo && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.reference1MobileNo.message}</p>}
                            </div>
                            {/* reference1Relation */}
                            <div className="sm:col-span-3">
                                <SelectInput
                                    label="relation / रिश्ता"
                                    placeholder="relation"
                                    options={relations}
                                    // required="required"
                                    {...register("reference1Relation", {
                                        value: `${formData?.samajReferenceDetails?.reference1Relation || ""}`,
                                    })}
                                    onChange={(e) => handleChange("reference1Relation", e)}
                                // onChange={(e) => handleCheckboxChange("gender", e.target.checked)}
                                />
                                {errors.reference1Relation && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.reference1Relation.message}</p>}
                            </div>
                        </div>
                        {/* <FamilyDetails members={formData?.memberFamilyDetails} setValue={setValue} /> */}
                        <FormHeading heading={'Others Details / अन्य विवरण'} />
                        {/* Achievements / awards------2000 characters------not compulsary */}
                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mx-5">
                            <div className=" sm:col-span-3  ">
                                <h3 className=' font-bold '>वाहन विवरण</h3>
                                <div className='flex'>
                                    <CheackBox
                                        label={'2 पहिया वाहन'}
                                        // placeholder={item.text}
                                        name="vahan Details"
                                        type="checkbox"
                                        checked={vehical['wheeler2']}
                                        onChange={(e) => handleCheckboxChange('wheeler2', e.target.checked)}
                                    />

                                    <CheackBox
                                        label={'4 पहिया वाहन'}
                                        // placeholder={item.text}
                                        name="vahan Details"
                                        type="checkbox"
                                        checked={vehical['wheeler4']}                                        // checked={formData?.othersDetails?.wheeler4}
                                        onChange={(e) => handleCheckboxChange('wheeler4', e.target.checked)}
                                    />

                                    {/* {errors.dharmashala && <p id="dharmashala-error" className="text-red-500 text-xs mt-1">{errors.dharmashala.message}</p>} */}
                                </div>
                            </div>

                            <div className=" sm:col-span-3  ">
                                <div className=" sm:col-span-3  ">
                                    <p className=' font-bold required'>मकान विवरण</p>
                                    <div className='flex  w-full justify-around'>
                                        <div>
                                            <input
                                                name='home'
                                                type='radio'
                                                value='स्वयं का'
                                                id='home-own'
                                                checked={home == 'स्वयं का'}
                                                {...register("home")}
                                                onChange={(e) => handleRadio('home', 'स्वयं का')}
                                            />
                                            <label htmlFor='home-own' className=' font-bold'>स्वयं का</label>
                                        </div>

                                        <div>
                                            <input
                                                name='home'
                                                type='radio'
                                                value='किराय का'
                                                id='home-rent'
                                                checked={home == 'किराय का'}
                                                {...register("home")}
                                                onChange={(e) => handleRadio('home', 'किराय का')}
                                            />
                                            <label htmlFor='home-rent' className=' font-bold'>किराय का</label>
                                            {/* किराय का */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <FormHeading heading={'Photo upload / फोटो अपलोड'} />
                        {/* 1 profile photo---compulsarypersonal
                        photo 1---compulsarypersonal
                        photo 2---compulsarypersonal
                        photo 3---compulsary1
                        Janam patrika---compulsary1
                        aadhar card---compulsary */}
                        {/* Father Photo */}
                        <AvatarEditorCom setEditedAvatar={setEditedAvatar} alreadySetAvatar={formData?.photos?.profilePhoto} />

                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mx-5">

                            {/* profile photo */}
                            {/* <div className="sm:col-span-3">
                                <Input
                                    label="profile photo / प्रोफ़ाइल फ़ोटो"
                                    placeholder="profile photo"
                                    type="file"
                                    required="required"
                                    onChange={(e) => handleFileChange("profilePhoto", e)}
                                />
                                {errors.profilePhoto && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.profilePhoto.message}</p>}
                            </div> */}
                            {/* photo 1 */}
                            <div className="sm:col-span-3">
                                <Input
                                    label="Personal 1 / व्यक्तिगत 1"
                                    placeholder="Personal 1"
                                    type="file"
                                    onChange={(e) => handleFileChange("personalPhoto1", e)}
                                />
                                {errors.personalPhoto1 && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.personalPhoto1.message}</p>}
                            </div>

                            {/* photo 2 */}
                            <div className="sm:col-span-3">
                                <Input
                                    label="Personal 2 / व्यक्तिगत 2"
                                    placeholder="Personal 2"
                                    type="file"
                                    onChange={(e) => handleFileChange("personalPhoto2", e)}
                                />
                                {errors.personalPhoto2 && <p id="username-error" className="text-red-500 text-xs mt-1">{errors.personalPhoto2.message}</p>}
                            </div>
                        </div>
                        <FormHeading heading={' Remarks/Notes / टिप्पणियाँ'} />
                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mx-5">
                            <div className="col-span-full  ">
                                <Textarea
                                    label="Remarks/Notes / टिप्पणियाँ"
                                    placeholder="Remarks/Notes / टिप्पणियाँ"
                                    type="text"
                                    {...register("remarksNotes", {

                                        value: `${formData?.remarksNotes || ""}`,
                                        minLength: {
                                            value: 3,
                                            message: "कृपया कम से कम 3 अक्षर भरें"
                                        },
                                        maxLength: {
                                            value: 1200,
                                            message: "कृपया 1200 अक्षरों से कम भरें"
                                        }
                                    })}
                                    onChange={(e) => handleChange("remarksNotes", e)}
                                />
                                {errors.remarksNotes && <p id="username-error" class="text-red-500 text-xs mt-1 ">{errors.remarksNotes.message}</p>}
                            </div>
                        </div>
                        {!adminEditData && <div className="mt-6 space-y-6 w-full text-center flex justify-center">
                            <div className="relative flex gap-x-3">
                                <div className="flex h-6 items-center">
                                    <input
                                        id="comments"
                                        name="comments"
                                        type="checkbox"
                                        // checked={isChecked}
                                        onClick={(e) => handelTermsAndConditions(e)}
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    />
                                </div>
                                <div className="text-sm leading-6">
                                    <label htmlFor="comments" className="font-medium text-gray-900">
                                        I agree to the terms and conditions
                                    </label>
                                </div>
                            </div>
                        </div>}
                    </div>
                    <div className="mt-6 flex items-center justify-center gap-x-6">
                        <Link to={'/'} type="button" className="text-sm font-semibold leading-6 text-gray-900">
                            Cancel
                        </Link>
                        {(!useData?.isLogin || useData?.user?.userType === 1) && <button
                            disabled={!termAndConditions && !adminEditData}
                            type="submit"
                            className={`rounded-md  ${!termAndConditions && !adminEditData ? 'bg-gray-400' : 'bg-indigo-600'} px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}                        >
                            Save
                        </button>}
                    </div>
                </form>
            </div>
        </>
    )
}

